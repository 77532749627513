import { Component } from '@angular/core';

@Component({
  selector: 'app-ci-sidenav-report',
  templateUrl: './ci-sidenav-report.component.html',
  styleUrls: ['./ci-sidenav-report.component.css']
})
export class CiSidenavReportComponent {

}
