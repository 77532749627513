import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { StatsCountService } from 'src/app/services/ci-stats-count/stats-count.service';
import { PAGINATE, validateAndBuildPayload } from 'src/app/utils/common';

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalRecords: 0,
  data: [],
};

@Component({
  selector: 'app-ci-stats-counts',
  templateUrl: './ci-stats-counts.component.html',
  styleUrls: ['./ci-stats-counts.component.css'],
})
export class CiStatsCountsComponent implements OnInit {
  totalRecordsCount: Subject<any> = new Subject();
  statsCountList: Array<any> = [];
  trafficSources: Array<any> = [];
  callDetails: Array<any> = [];

  totalRecords: number = 0;
  recordsPerPage: number = 5;
  activePage: number = 1;
  itemsToDisplay: Array<any> = [];

  isSearching: boolean = false;
  isFetching: boolean = false;

  formSearch: FormGroup = new FormGroup({
    landing_page: new FormControl(''),
    from_date: new FormControl(''),
    to_date: new FormControl(''),
  });

  CURRENT_API_CALL_STATUS: any = initialState;
  searchPayload: any = null;

  constructor(
    private formBuilder: FormBuilder,
    private statsCountService: StatsCountService,
    private toster: ToastrService
  ) {
    this.isFetching = true;
    this.statsCountService.fetchStatsCountByPageNumber(1, this.searchPayload);
    this.statsCountService.statsCount.subscribe((res) => {
      if (res.status) {
        this.CURRENT_API_CALL_STATUS = {
          currentPage: res.currentPage,
          totalPages: res.totalPages,
          totalRecords: res.totalRecords,
          data: res.data,
        };
        this.statsCountList = [...this.statsCountList, ...res.data];
        this.totalRecords = this.statsCountList.length;
        this.buildRecords(this.activePage);
        setTimeout(() => {
          this.totalRecordsCount.next(this.totalRecords);
        });
      }
      this.isSearching = false;
      this.isFetching = false;
    });
  }

  ngOnInit(): void {}

  fetchStatsByPageNumber(pageNumber: number) {
    if (Number(pageNumber) <= this.CURRENT_API_CALL_STATUS.totalPages) {
      this.isFetching = true;
      this.statsCountService.fetchStatsCountByPageNumber(
        Number(pageNumber) + 1,
        this.searchPayload
      );
    }
  }
  /*
  listStatsCount() {
    this.isFetching = true;
    this.statsCountService.listStatsCount().subscribe((res) => {
      if (res.status) {
        this.statsCountList = res.data;
        this.totalRecords = this.statsCountList.length;
        this.buildRecords(1);
      } else {
        this.toster.error('Failed to fetch stats Count.');
      }
      this.isFetching = false;
    });
  }
  */

  onSearch() {
    const payload = validateAndBuildPayload(this.formSearch.value, this.toster);
    if (!payload) {
      return;
    }
    this.resetDataState(false);
    this.searchPayload = payload;
    this.isSearching = true;
    this.fetchStatsByPageNumber(0);
  }

  resetDataState(flag: boolean = true) {
    this.isSearching = false;
    this.searchPayload = null;
    this.CURRENT_API_CALL_STATUS = initialState;
    this.activePage = 1;
    this.totalRecords = 0;
    this.statsCountList = [];
    if (flag) {
      this.statsCountService.fetchStatsCountByPageNumber(1);
    }
  }

  onSearchReset() {
    this.formSearch.patchValue({
      landing_page: '',
      from_date: '',
      to_date: '',
    });
    this.resetDataState();
  }

  buildRecords(pageNumber: number) {
    this.itemsToDisplay = PAGINATE(
      this.statsCountList,
      this.recordsPerPage,
      pageNumber
    );
  }

  pageEvent(event: any) {
    if (event.isFetchMore) {
      this.fetchStatsByPageNumber(
        this.CURRENT_API_CALL_STATUS.currentPage
      );
    }
    this.activePage = event.pageNumber;
    this.recordsPerPage = event?.pageSize;
    this.buildRecords(event?.pageNumber);
  }
}
