import { Component } from '@angular/core';

@Component({
  selector: 'app-ci-reports-sidenav',
  templateUrl: './ci-reports-sidenav.component.html',
  styleUrls: ['./ci-reports-sidenav.component.css']
})
export class CiReportsSidenavComponent {

}
