<app-ci-navbar></app-ci-navbar>
<div class="container mt-14 mx-auto mb-10 relative">
  <div class="relative">
    <div *ngIf="!isDasboard">
      <app-ci-sidenav></app-ci-sidenav>
    </div>
    <div [ngClass]="[isDasboard ? '': 'lg:ml-72']">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-ci-footer></app-ci-footer>