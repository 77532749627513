import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BACK_END_ENDPOINT, getToken } from 'src/app/utils/common';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  manageUsers: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) {}
  listUsers() {
    const formData = new FormData();
    formData.append('token', getToken());
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/list_user.php`,
      formData
    );
  }

  addUser(user: any) {
    const formData = new FormData();
    for (const key in user) {
      formData.append(key, user[key]);
    }
    formData.append('token', getToken());
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/create_user.php`,
      formData
    );
  }

  editUser(user: any) {
    const formData = new FormData();
    for (const key in user) {
      formData.append(key, user[key]);
    }
    formData.append('token', getToken());
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/update_user.php`,
      formData
    );
  }

  deleteUser(id: any) {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('token', getToken());
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/delete_user.php`,
      formData
    );
  }

  fetchManageUsersByPageNumber(pageNumber: any) {
    const formData = new FormData();
    formData.append('token', getToken());
    formData.append('page', pageNumber);

    this.http
      .post<any>(`${BACK_END_ENDPOINT}/api/list_user.php`, formData)
      .subscribe((res) => {
        this.manageUsers.next(res);
      });
  }
}
