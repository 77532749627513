import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/ci-account/account.service';
import { LogoutService } from 'src/app/services/ci-logout/logout.service';
import { USER_ROLE, USER_TOKEN } from 'src/app/utils/common';

@Component({
  selector: 'app-ci-logout',
  templateUrl: './ci-logout.component.html',
  styleUrls: ['./ci-logout.component.css'],
})
export class CiLogoutComponent {
  constructor(
    private logoutService: LogoutService,
    public router: Router,
    private accountService: AccountService
  ) {
    setTimeout(() => {
      localStorage.removeItem(USER_TOKEN);
      localStorage.removeItem(USER_ROLE);
      this.accountService.accountInfo.next({});
      this.router.navigate(['/']);
    }, 4000);
    this.logout();
  }

  logout() {
    this.logoutService.logout().subscribe((res) => {
      localStorage.removeItem(USER_TOKEN);
      localStorage.removeItem(USER_ROLE);
      this.accountService.accountInfo.next({});
      this.router.navigate(['/']);
    });
  }
}
