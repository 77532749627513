import * as d3 from 'd3';

const dlib: any = d3;

const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const MAX_BAR_WIDTH = 100;

const tooltip = dlib
    .select(".tooltip-bar")
    .style("opacity", 0);


const generateChartData = (isMultiSeries: any, series: any, numberOfRecords: any) => {
    if (isMultiSeries) {
        const columns = [];
        for (let i = 0; i < series.length + 1; i++) {
            if (i === 0) {
                columns.push("group");
            } else {
                columns.push(series[i - 1]);
            }
        }
        const data = [];
        for (let i = 0; i < numberOfRecords; i++) {
            const _temp: any = {};
            for (let j = 0; j < columns.length; j++) {
                if (columns[j] === "group") {
                    _temp["group"] = days[i];
                } else {
                    _temp[columns[j]] = Math.floor(Math.random() * 15) + 1;
                }
            }
            data.push(_temp);
        }
        return {
            data,
            columns,
        };
    }
    return {};
}

export const drawChart = (container: any, numberOfSeries: any, yText = "") => {
    var margin = { top: 10, right: 30, bottom: 40, left: 50 },
        width = +container.attr("width") - margin.left - margin.right,
        height = +container.attr("height") - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = container
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Parse the Data

    const { data, columns }: any = generateChartData(
        true,
        numberOfSeries,
        6
    );

    // List of subgroups = header of the csv files = soil condition here
    var subgroups = columns.slice(1);

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    var groups = dlib
        .map(data, (d: any) => {
            return d.group;
        })
    // .keys();
    // Add X axis
    var x = dlib.scaleBand().domain(groups).range([0, width]).padding([0.1]);
    svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(dlib.axisBottom(x).tickSize(0));

    // Add Y axis
    var y = dlib.scaleLinear().domain([0, 40]).range([height, 0]);
    svg.append("g").call(dlib.axisLeft(y));

    // Another scale for subgroup position?
    var xSubgroup = dlib
        .scaleBand()
        .domain(subgroups)
        .range([0, x.bandwidth()])
        .padding([0.05]);

    // color palette = one color per subgroup
    var color = dlib
        .scaleOrdinal()
        .domain(subgroups)
        .range([
            "#008FFB",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
            "rgb(119, 93, 208)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
            "rgb(119, 93, 208)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
            "rgb(119, 93, 208)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
        ]
        );
    // Show the bars
    svg
        .append("g")
        .selectAll("g")
        // Enter in data = loop group per group
        .data(data)
        .enter()
        .append("g")
        .attr("transform", function (d: any) {
            return "translate(" + x(d.group) + ",0)";
        })
        .selectAll("rect")
        .data(function (d: any) {
            return subgroups.map(function (key: any) {
                return { key: key, value: d[key] };
            });
        })
        .enter()
        .append("rect")
        .attr("x", function (d: any) {
            return xSubgroup(d.key);
        })

        .attr("y", function (d: any) {
            return y(d.value);
        })
        .on("mouseover", (d: any) => {
            tooltip.transition().duration(200).style("opacity", 0.9);
            tooltip
                .html(`${d.key}: <span>${d.value}</span>`)
            // .style("left", `${dlib.event.layerX}px`)
            //.style("top", `${dlib.event.layerY - 28}px`);
        })
        .on("mouseout", () =>
            tooltip.transition().duration(500).style("opacity", 0)
        )
        .transition()
        .duration(300)
        .ease(dlib.easeExp)
        .attr("width", xSubgroup.bandwidth())
        .transition()
        .duration(400)
        .ease(dlib.easeQuad)
        .attr("height", function (d: any) {
            return height - y(d.value);
        })
        .attr("fill", function (d: any) {
            return color(d.key);
        });

    svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("font-weight", "bold")
        .style("fill", "#5D5DAD")
        .text(yText);

    var legend = svg
        .selectAll(".legend")
        .data(subgroups)
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function (d: any, i: any) {
            return "translate(0," + i * 20 + ")";
        });

    legend
        .append("rect")
        .attr("x", width - 18)
        .attr("width", 18)
        .attr("height", 18)
        .style("fill", color)
        .on("click", function (d: any) {
            console.log("d", d);
        });

    legend
        .append("text")
        .attr("x", width - 24)
        .attr("y", 9)
        .attr("dy", ".35em")
        .style("text-anchor", "end")
        .style("fill", "#5D5DAD")
        .text(function (d: any) {
            return d;
        });

    d3.selectAll("text")
        .style("font-size", "12px")
        .style("fill", "#5D5DAD")
        .style("opacity", 1);
}

const formatData = (series: any, categories: any) => {
    const _temp: any = [];
    for (const key in categories) {
        const category = categories[key];

        const _group: any = {};
        _group['group'] = category;

        for (const _series in series) {
            const _data = series[_series];
            _group[_data.name] = _data?.data[key];
        }
        _temp.push(_group);
    }

    return _temp;
}

const textSize = (text: string) => {
    var container: any = d3.select('body').append('svg');
    container.append('text').text(text);
    var size = container.node().getBBox();
    container.remove();
    return { width: size.width, height: size.height };
}

const legendXPositionText = (data: any, position: any, textOffset: any, avgFontWidth: any, width: any) => {
    return legendXPosition(data, position, avgFontWidth, width) + textOffset;
}

const legendXPosition = (data: any, position: any, avgFontWidth: any, width: any) => {
    if (position == 0) {
        return 0;
    } else {
        var xPostiion = 0;
        for (let i = 0; i < position; i++) {
            xPostiion += (textSize(data[i]).width + 30);
        }
        return xPostiion;
    }
}


export const drawBarChart = (container: any, categories: Array<string>, series: Array<any>, yText = "") => {
    var margin = { top: 10, right: 30, bottom: 40, left: 50 },
        width = +container.attr("width") - margin.left - margin.right,
        height = +container.attr("height") - margin.top - margin.bottom;

    container.selectAll("*").remove();
    // append the svg object to the body of the page
    var svg = container
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Parse the Data
    const data = series;
    const columns = dlib
        .map(data, (d: any) => {
            return d.name.replace("'", "").trim();
        })

    var dataMax = d3.max(dlib.map(data, (d: any) => {
        return d3.max(d.data, (_d: any) => +_d)
    }));
    columns.unshift('group');
    const subgroups = columns.slice(1);
    const _data = formatData(data, categories);

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    var groups = dlib
        .map(_data, (d: any) => {
            return d.group;
        })
    // Add X axis
    var x = dlib.scaleBand().domain(groups).range([0, width]).padding([0.1]);
    svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(dlib.axisBottom(x).tickSize(0));

    // Add Y axis
    var y = dlib.scaleLinear().domain([0, dataMax]).range([height, 0]);
    svg.append("g").call(dlib.axisLeft(y));

    // Another scale for subgroup position?
    var xSubgroup = dlib
        .scaleBand()
        .domain(subgroups)
        .range([0, x.bandwidth()])
        .padding([0.1]);

    // color palette = one color per subgroup
    var color = dlib
        .scaleOrdinal()
        .domain(subgroups)
        .range([
            "#008FFB",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
            "rgb(119, 93, 208)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
            "rgb(119, 93, 208)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
            "rgb(119, 93, 208)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
        ]
        );
    // Show the bars
    svg
        .append("g")
        .selectAll("g")
        // Enter in data = loop group per group
        .data(_data)
        .enter()
        .append("g")
        .attr("transform", function (d: any) {
            return "translate(" + x(d.group) + ",0)";
        })
        .selectAll("rect")
        .data((d: any) => {
            return subgroups.map(function (key: any) {
                return { key: key, value: d[key] };
            });
        })
        .enter()
        .append("rect")
        .attr("x", function (d: any) {
            return xSubgroup(d.key);
        })

        .attr("y", function (d: any) {
            return y(d.value);
        })
        .on("mouseover", (event: any, d: any) => {
            tooltip.transition().duration(200).style("opacity", 0.9);
            tooltip
                .html(`${d.key}: <span>${d.value}</span>`)
        })
        .on("mousemove", (event: any) => {
            tooltip.style("top", (event.pageY - 30) + "px").style("left", (event.pageX + 12) + "px");
        })
        .on("mouseout", () =>
            tooltip.transition().duration(500).style("opacity", 0)
        )
        .transition()
        .duration(300)
        .ease(dlib.easeExp)
        .attr("width", Math.min(xSubgroup.bandwidth(), MAX_BAR_WIDTH))
        .transition()
        .duration(400)
        .ease(dlib.easeQuad)
        .attr("height", function (d: any) {
            return height - y(d.value || 0);
        })
        .attr("fill", function (d: any) {
            return color(d.key);
        });

    svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("font-weight", "bold")
        .style("fill", "#5D5DAD")
        .text(yText);

    const legend = svg
        .selectAll(".legend")
        .data(subgroups)
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function (d: any, i: any) {
            return "translate(0," + i * 20 + ")";
        });

    legend
        .append("rect")
        .attr("x", width - 18)
        .attr("width", 18)
        .attr("height", 18)
        .style("fill", color)
        .on("click", function (d: any) {
            console.log("d", d);
        });
    legend
        .append("text")
        .attr("x", width - 24)
        .attr("y", 9)
        .attr("dy", ".35em")
        .style("text-anchor", "end")
        .style("fill", "#5D5DAD")
        .text(function (d: any) {
            return d;
        });

    d3.selectAll("text")
        .style("font-size", "12px")
        .style("fill", "#5D5DAD")
        .style("opacity", 1);

}

export const drawSingleBarChart = (container: any, series: Array<any>, yText = "") => {
    var margin = { top: 10, right: 30, bottom: 40, left: 50 },
        width = +container.attr("width") - margin.left - margin.right,
        height = +container.attr("height") - margin.top - margin.bottom;
    container.selectAll("*").remove();
    // append the svg object to the body of the page
    var svg = container
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Parse the Data
    const data = series;
    const columns = dlib
        .map(data, (d: any) => {
            return d.name.replace("'", "").trim();
        })

    var dataMax = d3.max(dlib.map(data, (d: any) => {
        return d3.max(d.data, (_d: any) => +_d)
    }));

    var xScale = dlib.scaleBand().domain(data.map(function (d) { return d.name; })).range([0, width]).padding([0.1]);
    svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(dlib.axisBottom(xScale).tickSize(0));

    // Add Y axis
    var yScale = dlib.scaleLinear().domain([0, dataMax]).range([height, 0]);

    svg.append("g").call(dlib.axisLeft(yScale));
    var color = dlib
        .scaleOrdinal()
        .domain(columns)
        .range([
            "#008FFB",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
            "rgb(119, 93, 208)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
            "rgb(119, 93, 208)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
            "rgb(255, 69, 96)",
            "rgb(119, 93, 208)",
            "rgb(0, 143, 251)",
            "rgb(0, 227, 150)",
            "rgb(254, 176, 25)",
        ]
        );
    const _barWidth = Math.min(xScale.bandwidth(), MAX_BAR_WIDTH);
    svg.selectAll(".bar")
        .data(data)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("x", function (d: any) { return xScale(d.name) + (xScale.bandwidth() - MAX_BAR_WIDTH)/2; })
        .attr("y", function (d: any) {
            return yScale(d.data[0]);
        })
        .on("mouseover", (event: any, d: any) => {
            tooltip.transition().duration(200).style("opacity", 0.9);
            tooltip
                .html(`${d.name}: <span>${d.data[0]}</span>`)
        })
        .on("mousemove", (event: any) => {
            tooltip.style("top", (event.pageY - 30) + "px").style("left", (event.pageX + 12) + "px");
        })
        .on("mouseout", () =>
            tooltip.transition().duration(500).style("opacity", 0)
        )
        .attr("width", _barWidth)
        .attr("height", function (d: any) { return height - yScale(d.data[0]); })
        .attr("fill", function (d: any) {
            return color(d.name);
        });


    const legend = svg
        .selectAll(".legend")
        .data(columns)
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function (d: any, i: any) {
            return "translate(0," + i * 20 + ")";
        });

    legend
        .append("rect")
        .attr("x", width - 18)
        .attr("width", 18)
        .attr("height", 18)
        .style("fill", color)
        .on("click", function (d: any) {
            console.log("d", d);
        });
    legend
        .append("text")
        .attr("x", width - 24)
        .attr("y", 9)
        .attr("dy", ".35em")
        .style("text-anchor", "end")
        .style("fill", "#5D5DAD")
        .text(function (d: any) {
            return d;
        });


    d3.selectAll("text")
        .style("font-size", "12px")
        .style("fill", "#5D5DAD")
        .style("opacity", 1);


}