import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BACK_END_ENDPOINT,
  REPORT_TYPES,
  getToken,
} from 'src/app/utils/common';

import { forkJoin, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient) {}

  reportTypes: any = REPORT_TYPES;

  overviewReports(timeline: any, date: any) {
    const API_LIST = [];
    for (const k in this.reportTypes) {
      const formData = new FormData();
      formData.append('token', getToken());
      formData.append('type', this.reportTypes[k]);
      if (timeline) {
        formData.append('timeline', timeline?.toLowerCase());
      }
      if (date) {
        formData.append('custom_range', date);
      }
      API_LIST.push(
        this.http.post<any>(
          `${BACK_END_ENDPOINT}/api/overall_report.php`,
          formData
        )
      );
    }

    return forkJoin(API_LIST);
  }

  dailyReports(timeline: any, date: any) {
    const API_LIST = [];
    for (const k in this.reportTypes) {
      const formData = new FormData();
      formData.append('token', getToken());
      formData.append('type', this.reportTypes[k]);
      if (timeline) {
        formData.append('timeline', timeline?.toLowerCase());
      }
      if (date) {
        formData.append('custom_range', date);
      }

      API_LIST.push(
        this.http.post<any>(
          `${BACK_END_ENDPOINT}/api/daily_report.php`,
          formData
        )
      );
    }

    return forkJoin(API_LIST);
  }

  weeklyReports(timeline: any, date: any) {
    const API_LIST = [];
    for (const k in this.reportTypes) {
      const formData = new FormData();
      formData.append('token', getToken());
      formData.append('type', this.reportTypes[k]);
      if (timeline) {
        formData.append('timeline', timeline?.toLowerCase());
      }
      if (date) {
        formData.append('custom_range', date);
      }
      API_LIST.push(
        this.http.post<any>(
          `${BACK_END_ENDPOINT}/api/weekly_report.php`,
          formData
        )
      );
    }

    return forkJoin(API_LIST);
  }

  monthlyReports(timeline: any, date: any) {
    const API_LIST = [];
    for (const k in this.reportTypes) {
      const formData = new FormData();
      formData.append('token', getToken());
      formData.append('type', this.reportTypes[k]);
      if (timeline) {
        formData.append('timeline', timeline?.toLowerCase());
      }
      if (date) {
        formData.append('custom_range', date);
      }
      API_LIST.push(
        this.http.post<any>(
          `${BACK_END_ENDPOINT}/api/monthly_report.php`,
          formData
        )
      );
    }

    return forkJoin(API_LIST);
  }
}
