import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { BACK_END_ENDPOINT, getToken } from 'src/app/utils/common';

@Injectable({
  providedIn: 'root',
})
export class CallDataArchiveService {
  callDetailsArchive: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) {}

  listCallDuration() {
    const formData = new FormData();
    formData.append('token', getToken());
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/list_call_duration.php`,
      formData
    );
  }

  listTrafficSources() {
    const formData = new FormData();
    formData.append('token', getToken());
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/list_traffic_sources.php`,
      formData
    );
  }

  searchCallDetails(paylaod: any) {
    const formData = new FormData();
    for (const key in paylaod) {
      formData.append(key, paylaod[key]);
    }
    formData.append('token', getToken());
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/call_details_archive.php`,
      formData
    );
  }

  listCallDetails() {
    const formData = new FormData();
    formData.append('token', getToken());

    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/call_details_archive.php`,
      formData
    );
  }

  fetchCallDetailsArchiveByPageNumber(pageNumber: any, payload: any = null) {
    const formData = new FormData();
    formData.append('token', getToken());
    formData.append('page', pageNumber);

    if (payload) {
      for (const key in payload) {
        if (payload[key]) {
          formData.append(key, payload[key]);
        }
      }
    }

    this.http
      .post<any>(`${BACK_END_ENDPOINT}/api/call_details_archive.php`, formData)
      .subscribe((res) => {
        this.callDetailsArchive.next(res);
      });
  }
}
