<div>
    <div class="w-full px-4 py-2 bg-black lg:w-full" id="right-container">
        <div class="container mx-auto mt-12">
            <div class="flex items-center justify-between">
                <div class="text-gray-600 font-awesome ml-4">{{info[0]?.title}}</div>
                <div>
                    <!--<ci-timeline (callback)="(selectedTimelineCallback($event))" [isReport]="true"></ci-timeline>-->
                </div>
            </div>
            
            <div *ngIf="isFetching" class="flex flex-col items-center mt-8">
                <div class="text-gray-400 font-awesome text-2xl">
                    <div class="loader"></div>
                </div>
            </div>
            <div class="flex flex-col items-center mt-8">
                <div class="text-gray-400 font-awesome text-2xl">Call Summary</div>
                <div>
                    <svg width="500" height="400" id="{{reportTypes.call_summary}}"></svg>
                </div>
            </div>

            <div class="flex flex-col items-center mt-8">
                <div class="text-gray-400 font-awesome text-2xl">Landing Page Views</div>
                <div>
                    <svg width="500" height="400" id="{{reportTypes.landingpage_views}}"></svg>
                </div>
            </div>

            <!--<div class="flex flex-col items-center mt-8">
                <div class="text-gray-400 font-awesome text-2xl">Duration of Calls</div>
                <div>
                    <svg width="500" height="400" id="{{reportTypes.duration_calls}}"></svg>
                </div>
            </div>-->

            <div class="flex flex-col items-center mt-8">
                <div class="text-gray-400 font-awesome text-2xl">Calls Summary Based on Traffic Sources</div>
                <div>
                    <svg width="500" height="400" id="{{reportTypes.traffic_sources}}"></svg>
                </div>
            </div>

            <div class="flex flex-col items-center mt-8">
                <div class="text-gray-400 font-awesome text-2xl">Calls Summary Based on Cost of Traffic Sources</div>
                <div>
                    <svg width="500" height="400" id="{{reportTypes.cost_traffic_sources}}"></svg>
                </div>
            </div>

            <div class="flex flex-col items-center mt-8">
                <div class="text-gray-400 font-awesome text-2xl">Calls Summary Based on Vendors</div>
                <div>
                    <svg width="500" height="400" id="{{reportTypes.call_summary_vendors}}"></svg>
                </div>
            </div>
            <div class="flex flex-col items-center mt-8">
                <div class="text-gray-400 font-awesome text-2xl">&nbsp;</div>
            </div>
        </div>
    </div>
</div>