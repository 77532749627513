<aside id="default-arrow"
    class="fixed top-16 left-0 lg:-left-52 h-screen transition-transform -translate-x-full sm:translate-x-0 lg:block  z-50"
    aria-label="Sidebar" style="transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895)">
    <div *ngIf="!showMobileMenu" class="cursor-pointer" (click)="onShowMobileMenu()">
        <img src="assets/img/arrow-right.svg" class="h-5 w-5" alt="Arrow Right" />
    </div>
</aside>

<aside id="default-sidebar"
    class="fixed top-14 lg:absolute lg:top-2 lg:left-0 w-72 h-screen transition-transform -translate-x-full sm:translate-x-0 lg:block z-50"
    aria-label="Sidebar" style="transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895)"
    [ngClass]="[showMobileMenu ? 'left-0' : '-left-[90rem]']">

    <div *ngIf="showMobileMenu" class="cursor-pointer mb-1 lg:hidden" (click)="onShowMobileMenu()">
        <img src="assets/img/cross.svg" class="h-5 w-5" alt="Close" />
    </div>
    <div class="px-4 py-2 bg-blac border-r-2 h-full bg-gray-900 pb-5 border-t-2 border-gray-800">
        <ul class="text-sm pt-1">
            <li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                [routerLinkActiveOptions]="{ exact: true }"
                class="border-b-2 border-gray-800 mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                <a routerLink="/statistics" [routerLinkActiveOptions]="{ exact: true }"
                    [routerLinkActive]="['text-white']"
                    class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-card-checklist inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                        <path
                            d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path
                            d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                    </svg>
                    Stats
                </a>
            </li>
            <li *ngIf="!isUser"
                [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                [routerLinkActiveOptions]="{ exact: true }"
                class="border-b-2 border-gray-800 mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                <a routerLink="/statistics/manage-users" [routerLinkActive]="['text-white']"
                    class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-flower2 inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                        <path
                            d="M8 16a4 4 0 0 0 4-4 4 4 0 0 0 0-8 4 4 0 0 0-8 0 4 4 0 1 0 0 8 4 4 0 0 0 4 4zm3-12c0 .073-.01.155-.03.247-.544.241-1.091.638-1.598 1.084A2.987 2.987 0 0 0 8 5c-.494 0-.96.12-1.372.331-.507-.446-1.054-.843-1.597-1.084A1.117 1.117 0 0 1 5 4a3 3 0 0 1 6 0zm-.812 6.052A2.99 2.99 0 0 0 11 8a2.99 2.99 0 0 0-.812-2.052c.215-.18.432-.346.647-.487C11.34 5.131 11.732 5 12 5a3 3 0 1 1 0 6c-.268 0-.66-.13-1.165-.461a6.833 6.833 0 0 1-.647-.487zm-3.56.617a3.001 3.001 0 0 0 2.744 0c.507.446 1.054.842 1.598 1.084.02.091.03.174.03.247a3 3 0 1 1-6 0c0-.073.01-.155.03-.247.544-.242 1.091-.638 1.598-1.084zm-.816-4.721A2.99 2.99 0 0 0 5 8c0 .794.308 1.516.812 2.052a6.83 6.83 0 0 1-.647.487C4.66 10.869 4.268 11 4 11a3 3 0 0 1 0-6c.268 0 .66.13 1.165.461.215.141.432.306.647.487zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                    </svg>
                    Manage Users
                </a>
            </li>
            <li *ngIf="!isUser"
                [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                [routerLinkActiveOptions]="{ exact: true }"
                class="border-b-2 border-gray-800 mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                <a routerLink="/statistics/cost-settings" [routerLinkActive]="['text-white']"
                    class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-activity inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                    </svg>
                    Cost Settings
                </a>
            </li>
            <li *ngIf="false" [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                [routerLinkActiveOptions]="{ exact: true }"
                class="border-b-2 border-gray-800 mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                <a routerLink="/statistics/call-data" [routerLinkActive]="['text-white']"
                    class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-bounding-box inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                        <path
                            d="M5 2V0H0v5h2v6H0v5h5v-2h6v2h5v-5h-2V5h2V0h-5v2H5zm6 1v2h2v6h-2v2H5v-2H3V5h2V3h6zm1-2h3v3h-3V1zm3 11v3h-3v-3h3zM4 15H1v-3h3v3zM1 4V1h3v3H1z" />
                    </svg>
                    Call Data
                </a>
            </li>
            <li *ngIf="false" [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                [routerLinkActiveOptions]="{ exact: true }"
                class="border-b-2 border-gray-800 mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                <a routerLink="/statistics/call-data-archive" [routerLinkActive]="['text-white']"
                    class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-calendar-week inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                        <path
                            d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                        <path
                            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                    </svg>
                    Call Data Archive
                </a>
            </li>
            <li *ngIf="false" [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                [routerLinkActiveOptions]="{ exact: true }"
                class="border-b-2 border-gray-800 mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                <a routerLink="/statistics/stats-counts" [routerLinkActive]="['text-white']"
                    class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-calculator inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                        <path
                            d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                        <path
                            d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
                    </svg>
                    Stats Counts
                </a>
            </li>
            <li *ngIf="false" class="-mt-2 border-b-2 border-gray-800">
                <button type="button" (click)="expandReport($event)" [ngClass]="[expand ? 'text-white': '']"
                    class="flex items-center w-full p-2 pl-2 text-gray-900 transition duration-75 rounded-lg group hover:dark:text-white text-purpleBlue">
                    <svg xmlns="http://www.w3.org/2000/svg" class="inline-block w-6 h-6 mr-2 -mt-1 ml-1" fill="none"
                        width="16" height="16" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    <span class="flex-1 ml-1 text-left whitespace-nowrap">Reports</span>
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"></path>
                    </svg>
                </button>
                <ul class="py-2 space-y-2" *ngIf="expand">
                    <li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="border-b-2 border-gray-800 mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"
                        [routerLinkActiveOptions]="{ exact: true }">
                        <a routerLink="/statistics/reports" [routerLinkActive]="['text-white']"
                            [routerLinkActiveOptions]="{ exact: true }"
                            class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white pl-11">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-graph-up inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z" />
                            </svg>
                            Overview
                        </a>
                    </li>
                    <!--<li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="border-b-2 border-gray-800 mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                        <a routerLink="/statistics/reports/daily" [routerLinkActive]="['text-white']"
                            class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white pl-11">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-send inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                                <path
                                    d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                            </svg>
                            Daily
                        </a>
                    </li>-->
                    <li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="border-b-2 border-gray-800 mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 pl-7">
                        <a routerLink="/statistics/reports/weekly" [routerLinkActive]="['text-white']"
                            class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-activity inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                            </svg>
                            Weekly
                        </a>
                    </li>
                    <li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 pl-7">
                        <a routerLink="/statistics/reports/monthly" [routerLinkActive]="['text-white']"
                            class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-diagram-2-fill inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-3 8A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1z" />
                            </svg>
                            Monthly
                        </a>
                    </li>
                </ul>
            </li>
            <li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                [routerLinkActiveOptions]="{ exact: true }"
                class="border-b-2 border-gray-800 mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                <a routerLink="/statistics/account" [routerLinkActive]="['text-white']"
                    class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-gear inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                        <path
                            d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                        <path
                            d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                    </svg>
                    Account
                </a>
            </li>
            <li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']"
                [routerLinkActiveOptions]="{ exact: true }"
                class="mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                <a routerLink="/logout" [routerLinkActive]="['text-white']"
                    class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-diagram-2 inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM3 11.5A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                    </svg>
                    Logout
                </a>
            </li>
        </ul>
    </div>
</aside>