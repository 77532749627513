<!-- Navbar -->
<ng-template #menuTemplate>
    <div class="items-center justify-between w-full md:flex md:w-auto md:order-1"
        [ngClass]="{'lg:hidden': !isAccount, 'hidden': isAccount && !showMobileMenu }">
        <ul
            class="flex flex-col p-4 md:p-0 mt-4 font-medium md:flex-row md:space-x-8 border md:mt-0 md:border-0 dark:border-gray-700 mx-5 rounded-lg dark:bg-blueishBlack">
            <li [routerLinkActive]="['border-b-2', 'border-blue-500', 'text-white']"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:border-b-2 hover:border-blue-500 h-14 items-center flex text-purpleBlue">
                <a routerLink="dashboard" class="block py-2 pl-3 pr-4 rounded md:bg-transparent">Dashboard</a>
            </li>
            <li [routerLinkActive]="['border-b-2', 'border-blue-500', 'text-white']"
                class="hover:border-b-2 hover:border-blue-500 h-14 items-center flex text-purpleBlue">
                <a routerLink="/statistics" class="block py-2 pl-3 pr-4 rounded md:bg-transparent">Statistics</a>
            </li>
        </ul>
    </div>
</ng-template>
<nav class="fixed w-full z-20 top-0 left-0 md:dark:bg-blueishBlack">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto dark:bg-blueishBlack">

        <a href="/dashboard" class="flex items-center">
            <img src="/assets/img/sitelogo.png" class="w-16 ml-6" />
        </a>
        <!--<div class="hidden md:invisible items-center justify-between w-full md:flex md:w-auto md:order-1" id="navbar-search">
            <div class="relative" style="width: 400px;">
                <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-700" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"></path>
                    </svg>
                </div>
                <input type="text" id="search-navbar"
                    class="block w-full p-2 pr-10 text-sm text-gray-900 border dark:bg-gray-950 dark:border-gray-800 dark:placeholder-gray-400 "
                    placeholder="">
            </div>
        </div>-->
        <div class="w-full hidden lg:block  md:w-auto" style="width: 400px;">&nbsp</div>
        <div class="flex md:order-2 items-center md:mr-4" [ngClass]="[isAccount ? '': 'hidden']">
            <button id="userProfile" data-dropdown-toggle="dropdownAvatar"
                class="p-2 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                type="button">
                <img src="assets/img/Profile.svg" class="h-5 w-5" alt="Profile" />
            </button>
            <!-- Dropdown menu -->
            <div id="dropdownAvatar"
                class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-900 dark:divide-gray-700">
                <div class="px-4 py-3 text-sm text-purpleBlue">
                    <div>{{account.username}}</div>
                    <div class="font-medium truncate">{{account.email}}</div>
                </div>
                <ul class="py-2 text-sm text-purpleBlue" aria-labelledby="dropdownUserAvatarButton">
                    <li>
                        <a href="/statistics/account"
                            class="block px-4 py-2 hover:text-white hover:bg-gray-100 dark:hover:bg-gray-600 text-purpleBlue">Edit
                            Profile</a>
                    </li>
                </ul>
                <div class="py-2">
                    <a href="/logout"
                        class="block px-4 py-2 text-sm hover:text-white hover:bg-gray-100 dark:hover:bg-gray-600 text-purpleBlue ">Sign
                        out</a>
                </div>
            </div>
        </div>
        <button (click)="onShowMobileMenu()" type="button"
            class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 mr-4"
            aria-controls="navbar-default" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M1 1h15M1 7h15M1 13h15" />
            </svg>
        </button>
        <div class="hidden md:block">
            <div *ngTemplateOutlet="menuTemplate">
            </div>
        </div>
    </div>
    <div class="block md:hidden">
        <div *ngTemplateOutlet="menuTemplate">
        </div>
    </div>
</nav>