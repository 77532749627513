<div>
    <div class="w-full px-4 py-2 bg-black">
        <div class="container mx-auto mt-4">

            <div class="w-full bg-black lg:w-full">
                <div class="container mx-auto">

                    <form [formGroup]="formSearch" (ngSubmit)="onSearch()" class="flex items-center">
                        <div class="mr-4">
                            <label for="simple-search" class="sr-only">Search</label>
                            <div class="relative w-full">
                                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <input formControlName="phone" name="phone" id="phone" type="text" id="simple-search"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Search Phone..">
                            </div>
                        </div>
                        <div class="mr-4" style="width: 220px;">
                            <label for="countries" class="sr-only">Select an
                                option</label>
                            <select formControlName="traffic_source" name="traffic_source" id="traffic_source"
                                id="countries"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="">Traffic&nbsp;Sources</option>
                                <option *ngFor="let item of trafficSources;" value="{{item.id}}">{{ item.traffic_source
                                    }}</option>
                            </select>
                        </div>
                        <div class="mr-4" style="width: 220px;">
                            <label for="countries" class="sr-only">Select an
                                option</label>
                            <select formControlName="call_duration" name="call_duration" id="call_duration"
                                id="countries"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="">Call&nbsp;Duration</option>
                                <option *ngFor="let item of callDuration;" value="{{item.id}}">{{ item.call_duration }}
                                </option>
                            </select>
                        </div>
                        <div class="mr-4">
                            <label for="countries" class="sr-only">Select an
                                option</label>
                            <input formControlName="from_date" name="from_date" id="from_date"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5  dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                type="date" name="from_date" value="01/01/2018" />
                        </div>
                        <div class="text-gray-500 mr-5">
                            to
                        </div>
                        <div class="mr-4">
                            <label for="countries" class="sr-only">Select an
                                option</label>
                            <input formControlName="to_date" name="to_date" id="to_date"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5  dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                type="date" name="to_date" value="01/15/2018" />
                        </div>
                        <div class="mr-4">
                            <button type="submit" *ngIf="!isSearching"
                                class="bg-blue-500 hover:bg-blue-700 text-white font-awesome py-2 px-4 border border-blue-700 rounded">
                                <span *ngIf="!isSearching">Search</span>
                            </button>

                            <button type="submit" *ngIf="isSearching" disabled
                                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                                <svg aria-hidden="true" role="status"
                                    class="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="#E5E7EB" />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentColor" />
                                </svg>
                                Loading...
                            </button>
                        </div>
                        <div>
                            <button type="button" (click)="onSearchReset()" [disabled]="isSearching"
                                class="bg-red-500 hover:bg-red-700 text-white font-awesome py-2 px-4 border border-blue-700 rounded">
                                Reset
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="callDetailsArchive.length >0" class="flex flex-col mt-8">
                <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div>
                        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-900 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Full Name
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Phone
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Duration
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Cert
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" class="px-6 py-3 w-6">
                                            Recording
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white border-b dark:border-gray-700 even:bg-gray-900 odd:bg-gray-800"
                                        *ngFor="let item of itemsToDisplay;">
                                        <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap">
                                            {{item.first_name}} {{ item.last_name }}
                                        </th>
                                        <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap">
                                            <a (click)="showInfo($event, item, true)"
                                                class="text-blue-600">{{item.phone}}</a>
                                        </th>
                                        <td class="px-6 py-4">
                                            {{item.call_duration}}
                                        </td>
                                        <td class="px-6 py-4">
                                            <a *ngIf="item.tf_cert && !item.tf_claimed_cert" href="{{item.tf_cert}}"
                                                target="_blank"
                                                class="bg-yellow-500 hover:bg-blue-700 text-white font-awesome py-2 px-4 border border-blue-700 rounded">View</a>
                                            <a *ngIf="!item.tf_cert && item.tf_claimed_cert"
                                                href="{{item.tf_claimed_cert}}" target="_blank"
                                                class="bg-blue-500 hover:bg-yellow-700 text-white font-awesome py-2 px-4 border border-yellow-700 rounded">View</a>
                                        </td>
                                        <td class="px-6 py-4">
                                            {{item.call_status}}
                                        </td>
                                        <td class="px-6 py-4 text-center">
                                            <audio class="text-gray-600" preload="auto" controls=""
                                                controlslist="noplaybackrate  nodownload" id="2481191-myAudio" on="">
                                                <source src="{{item.file_location}}">
                                            </audio>
                                        </td>
                                        <td class="px-6 py-4 text-center">
                                            {{item.call_date}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="totalRecords > 0">
                            <app-ci-pagination [totalCount]="totalRecordsCount" [index]="activePage"
                                (page)="pageEvent($event)"></app-ci-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isFetching && totalRecords === 0" class="mt-5 text-3xl text-gray-600">No Details Found</div>

        </div>
    </div>
</div>

<div *ngIf="infoModal" id="delete-user-modal" tabindex="-1"
    class="fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full h-full max-w-md md:h-auto mx-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

            <div class="p-6 text-blue-400">

                <div class="mb-2">
                    <span class="font-semibold text-gray-500">First Name:&nbsp;</span>
                    <span>{{infoData?.first_name}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Last Name:&nbsp;</span>
                    <span>{{infoData?.last_name}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Address:&nbsp;</span> <span>{{infoData?.address}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">State:&nbsp;</span> <span>{{infoData?.state}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Zip:&nbsp;</span> <span>{{infoData?.zip}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Email:&nbsp;</span> <span>{{infoData?.email}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Call Date:&nbsp;</span>
                    <span>{{infoData?.call_date}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Call Duration:&nbsp;</span>
                    <span>{{infoData?.call_duration}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Call Type:&nbsp;</span>
                    <span>{{infoData?.call_type}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Call Status:&nbsp;</span>
                    <span>{{infoData?.call_status}}</span>
                </div>
                <div class="mb-5">
                    <span class="font-semibold text-gray-500">Traffic Sources:&nbsp;</span>
                    <span>{{infoData?.traffic_sources}}</span>
                </div>
                <div class="mb-4">
                    <a *ngIf="infoData.tf_cert" href="{{infoData.tf_cert}}" target="_blank"
                        class="bg-yellow-500 hover:bg-blue-700 text-white font-awesome py-2 px-4 border border-blue-700 rounded">Click
                        here to view TF Cert</a>
                </div>
                <div class="mb-4">
                    <a *ngIf="infoData.tf_claimed_cert" href="{{infoData.tf_claimed_cert}}" target="_blank"
                        class="bg-blue-500 hover:bg-yellow-700 text-white font-awesome py-2 px-4 border border-yellow-700 rounded">Click
                        here to view Claimed Cert</a>
                </div>
                <hr class="h-px bg-gray-200 border-0 dark:bg-blue-500">
                <div class="flex justify-end">
                    <button (click)="showInfo($event, null, false)" data-modal-hide="popup-modal" type="button"
                        class="mt-2 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-red-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        close</button>
                </div>
            </div>
        </div>
    </div>
</div>