import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/ci-auth/auth-service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { USER_TOKEN } from 'src/app/utils/common';

@Component({
  selector: 'app-ci-login',
  templateUrl: './ci-login.component.html',
  styleUrls: ['./ci-login.component.css'],
})
export class CiLoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  submitted = false;
  isFetching: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public router: Router,
    private encryptDecryptService: EncryptDecryptService
  ) {
    if (
      localStorage.getItem(USER_TOKEN) &&
      localStorage.getItem(USER_TOKEN)?.trim()
    ) {
      router.navigate(['/dashboard']);
    }
    this.authService.userInfo.subscribe(res => {
      if (res.status) {
        this.router.navigate(['/dashboard']);
      }
      this.isFetching = false;
    });
  }

  ngOnInit(): void {}

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.isFetching = true;
    this.authService.signIn({
      username: this.form.value.username,
      password: this.form.value.password,
    });
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }
}
