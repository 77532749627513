<div>
    <div class="w-full px-4 py-1 bg-black">
        <div class="container mx-auto">
            <div class="flex items-center justify-between mt-8">
                <div>&nbsp;</div>
                <div>
                    <button (click)="openAddUserModal()"
                        class="bg-blue-500 hover:bg-blue-700 text-white font-awesome py-2 px-4 border border-blue-500 rounded">
                        <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Add User</button>
                </div>
            </div>
            <div class="flex flex-col mt-3">
                <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div>
                        <div *ngIf="isFetching" class="flex flex-col items-center mt-8 mb-2">
                            <div class="text-gray-400 font-awesome text-2xl">
                                <div class="loader"></div>
                            </div>
                        </div>
                        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead
                                    class="text-xs text-gray-700 uppercase bg-gray-900 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            User
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Phone Number
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Username
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white border-b dark:border-gray-700 even:bg-gray-900 odd:bg-gray-800"
                                        *ngFor="let list of itemsToDisplay">
                                        <th scope="row"
                                            class="px-6 py-4 font-medium whitespace-nowrap text-blue-600 dark:text-blue-500">
                                            <a href="#" (click)="showInfo($event, list, true)">{{list.last_name}} {{list.first_name}}</a>
                                        </th>
                                        <td class="px-6 py-4">
                                            {{list.email}}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{list.phone}}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{list.username}}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{list.status}}
                                        </td>
                                        <td class="px-6 py-4 text-center">
                                            <a href="#" (click)="editUser($event, list)"
                                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"><i
                                                    class="fa fa-edit" aria-hidden="true"></i></a>&nbsp;&nbsp;
                                            <a *ngIf="!isAdmin" href="#" (click)="deleteUser($event, list)"
                                                class="font-medium text-red-600 dark:text-red-500 hover:underline"><i
                                                    class="fa fa-trash" aria-hidden="true"></i></a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="totalRecords > 0">
                            <app-ci-pagination [totalCount]="totalRecordsCount"
                                [index]="activePage" (page)="pageEvent($event)"></app-ci-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="showDeleteUser" id="delete-user-modal" tabindex="-1"
    class="fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full h-full max-w-md md:h-auto mx-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

            <div class="p-6 text-center">
                <svg aria-hidden="true" class="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none"
                    stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete
                    this user?</h3>
                <button (click)="confirmDeleteUser(true)" data-modal-hide="popup-modal" type="button"
                    class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                    Yes, I'm sure
                </button>
                <button (click)="confirmDeleteUser(false)" data-modal-hide="popup-modal" type="button"
                    class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No,
                    cancel</button>
            </div>
        </div>
    </div>
</div>


<!-- Add user modal -->
<div *ngIf="showAddUserModal" id="add-user-modal" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full bg-gray-800 bg-opacity-50">
    <div class="relative w-full h-full max-w-md md:h-auto mx-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" (click)="closeAddUserModal()"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="add-user-modal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">{{isEdit ? 'Edit User' : 'Add User'}}
                </h3>
                <form [formGroup]="form" (ngSubmit)="onSubmit()" class="space-y-6" action="#">
                    <div>
                        <label for="fname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First
                            Name*</label>
                        <input type="text" formControlName="first_name" name="first_name" id="first_name"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && f['first_name'].errors }">
                        <div *ngIf="submitted && f['first_name'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['first_name'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="lname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last
                            Name*</label>
                        <input type="text" formControlName="last_name" name="last_name" id="last_name"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && f['last_name'].errors }">
                        <div *ngIf="submitted && f['last_name'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['last_name'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="email"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email*</label>
                        <input type="email" formControlName="email" name="email" id="email"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && f['email'].errors }">
                        <div *ngIf="submitted && f['email'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['email'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="username"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username*</label>
                        <input type="text" formControlName="username" name="username" id="username"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && f['username'].errors }">
                        <div *ngIf="submitted && f['username'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['username'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="phone"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone*</label>
                        <input type="text" formControlName="phone" name="phone" id="phone"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && f['phone'].errors }">
                        <div *ngIf="submitted && f['phone'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['phone'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="password"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password*</label>
                        <input type="password" formControlName="password" name="password" id="password"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && f['password'].errors }">
                        <div *ngIf="submitted && f['password'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['password'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="address"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Address*</label>
                        <input type="text" formControlName="address" name="address" id="address"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && f['address'].errors }">
                        <div *ngIf="submitted && f['address'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['address'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="city"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">City*</label>
                        <input type="text" formControlName="city" name="city" id="city"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && f['city'].errors }">
                        <div *ngIf="submitted && f['city'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['city'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="state"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">State*</label>
                        <input type="text" formControlName="state" name="state" id="state"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && f['state'].errors }">
                        <div *ngIf="submitted && f['state'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['state'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="zip"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ZIP*</label>
                        <input type="text" formControlName="zip" name="zip" id="zip"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && f['zip'].errors }">
                        <div *ngIf="submitted && f['zip'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['zip'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="status"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status*</label>
                        <select [ngClass]="{ 'border-red-500': submitted && f['status'].errors }"
                            formControlName="status" id="status" name="status"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="Active">Active</option>
                            <option value="Inactive ">Inactive</option>
                        </select>
                        <div *ngIf="submitted && f['status'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="f['status'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>

                    <button type="submit" [disabled]="isSubmitting"
                        class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        {{isSubmitting ? 'Wait.. ': isEdit ? 'Edit' : 'Add'}}</button>
                    <button type="Button" (click)="closeAddUserModal()" [disabled]="isSubmitting"
                        class="w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800">
                        Cancel</button>

                </form>
            </div>
        </div>
    </div>
</div>
<!-- user info modal -->
<div *ngIf="infoModal" id="delete-user-modal" tabindex="-1"
    class="fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full h-full max-w-md md:h-auto mx-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="text-center">
                <svg aria-hidden="true" class="mx-auto text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </div>
            <div class="p-6 text-blue-400">
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">First Name:&nbsp;</span>
                    <span>{{infoData?.first_name}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Last Name:&nbsp;</span>
                    <span>{{infoData?.last_name}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Address:&nbsp;</span> <span>{{infoData?.address}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">State:&nbsp;</span> <span>{{infoData?.state}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Zip:&nbsp;</span> <span>{{infoData?.zip}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Email:&nbsp;</span> <span>{{infoData?.email}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">City:&nbsp;</span>
                    <span>{{infoData?.city}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Phone:&nbsp;</span>
                    <span>{{infoData?.phone}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">State:&nbsp;</span>
                    <span>{{infoData?.status}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Username:&nbsp;</span>
                    <span>{{infoData?.username}}</span>
                </div>
                                       
                <hr class="h-px bg-gray-200 border-0 dark:bg-blue-500">
                <div class="flex justify-end">
                    <button (click)="showInfo($event, null, false)" data-modal-hide="popup-modal" type="button"
                    class="mt-2 text-gray-500 bg-white hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-blue-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    Change Password</button>&nbsp;&nbsp;
                    <button (click)="showInfo($event, null, false)" data-modal-hide="popup-modal" type="button"
                        class="mt-2 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-red-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        close</button>
                       
                </div>
            </div>
        </div>
    </div>
</div>

<!-- user info modal -->
<div *ngIf="infoModal" id="delete-user-modal" tabindex="-1"
    class="fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full h-full max-w-md md:h-auto mx-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="text-center">
                <svg aria-hidden="true" class="mx-auto text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </div>
            <div class="p-6 text-blue-400">
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">First Name:&nbsp;</span>
                    <span>{{infoData?.first_name}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Last Name:&nbsp;</span>
                    <span>{{infoData?.last_name}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Address:&nbsp;</span> <span>{{infoData?.address}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">State:&nbsp;</span> <span>{{infoData?.state}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Zip:&nbsp;</span> <span>{{infoData?.zip}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Email:&nbsp;</span> <span>{{infoData?.email}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">City:&nbsp;</span>
                    <span>{{infoData?.city}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Phone:&nbsp;</span>
                    <span>{{infoData?.phone}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">State:&nbsp;</span>
                    <span>{{infoData?.status}}</span>
                </div>
                <div class="mb-2">
                    <span class="font-semibold text-gray-500">Username:&nbsp;</span>
                    <span>{{infoData?.username}}</span>
                </div>
                                       
                <hr class="h-px bg-gray-200 border-0 dark:bg-blue-500">
                <div class="flex justify-end">
                    <button (click)="showChangePasswordDialog($event)" data-modal-hide="popup-modal" type="button"
                    class="mt-2 text-gray-500 bg-white hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-blue-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    Change Password</button>&nbsp;&nbsp;
                    <button (click)="showInfo($event, null, false)" data-modal-hide="popup-modal" type="button"
                        class="mt-2 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-red-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        close</button>
                       
                </div>
            </div>
        </div>
    </div>
</div>

<!--Change Password modal -->
<div *ngIf="openChangePassword" id="add-user-modal" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full bg-gray-800 bg-opacity-50">
    <div class="relative w-full h-full max-w-md md:h-auto mx-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" (click)="reset()"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="add-user-modal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Change Password</h3>
                <form [formGroup]="formChangePassword" (ngSubmit)="onChangePasswordSubmit()" class="space-y-6"
                    action="#">


                    <div>
                        <label for="password"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password*</label>
                        <input type="password" formControlName="password" name="password" id="password"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fc['password'].errors }">
                        <div *ngIf="submitted && fc['password'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fc['password'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>

                    <div>
                        <label for="password"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm
                            Password*</label>
                        <input type="password" formControlName="confirmPassword" name="confirmPassword"
                            id="confirmPassword"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fc['confirmPassword'].errors }">
                        <div *ngIf="submitted && fc['confirmPassword'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fc['confirmPassword'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>

                    <div *ngIf="submitted && formChangePassword?.errors" class="text-red-500 text-xs italic mt-1">
                        <div *ngIf="formChangePassword?.errors">Password does not match..</div>
                    </div>

                    <button type="submit"
                        class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Change</button>
                    <button type="Button" (click)="reset()"
                        class="w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800">
                        Cancel</button>

                </form>
            </div>
        </div>
    </div>
</div>