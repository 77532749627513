import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { resetChartTooltip } from './utils/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'click-it';
  isDasboard: boolean = true;
  constructor(private router: Router) {
    this.router.events.subscribe((res) => {
      resetChartTooltip();
      this.isDasboard = this.router.url === '/dashboard' || this.router.url === '/' || this.router.url === '/logout';
    });
  }
}
