<!-- Flex Container -->
<div class="container flex px-6 py-20 mx-auto space-y-12 md:py-12 flex-col lg:flex-row md:space-y-0">
    <!-- General statistics -->
    <div class="lg:mr-10 flex flex-col items-center w-full justify-center">
        <div class="font-awesome text-center text-white text-2xl">
            General statistics
        </div>
        <div class="bg-blueishBlack mt-6 rounded-md px-4 py-4 text-white flex items-center justify-center w-full lg:w-[18rem]">
            <div [ngClass]="[DA?.total_earnings?.amount?.length > 8 ? 'mr-2': 'mr-5']">
                <svg width="60" height="60" id="total-earnings"></svg>
            </div>
            <div>
                <div class="text-sm">{{DA?.total_earnings?.title}}</div>
                <div class="flex">
                    <div class="font-awesome mr-2 text-sm">{{DA?.total_earnings?.amount}}</div>
                    <div *ngIf="DA?.total_earnings?.status === 'positive'"
                        class="text-green-300 mt-2 mr-1 h-0  text-sm"><i class="fa-solid fa-sort-up"></i></div>
                    <div *ngIf="DA?.total_earnings?.status === 'negative'" class="text-red-300 -mt-1 mr-1 h-0  text-sm">
                        <i class="fa-solid fa-sort-down"></i>
                    </div>
                    <div *ngIf="DA?.total_earnings?.status === 'positive'" class="text-green-300  text-sm">
                        {{DA?.total_earnings?.percent}}</div>
                    <div *ngIf="DA?.total_earnings?.status === 'negative'" class="text-red-300  text-sm">
                        {{DA?.total_earnings?.percent}}</div>
                </div>
            </div>
        </div>

        <div class="bg-blueishBlack mt-6 rounded-md px-4 py-4 text-white w-full lg:w-[18rem]">
            <div class="mr-6 flex flex-col items-center justify-center">
                <svg width="250" height="69" id="buyer_chart"></svg>
            </div>
            <div class="mt-6 flex flex-col items-center justify-center">
                <div class="text-sm">{{DA?.buyers?.title}}</div>
                <div class="flex">
                    <div class="font-awesome mr-2">{{DA?.buyers?.amount}}</div>
                    <div *ngIf="DA?.total_earnings?.status === 'positive'" class="text-green-300 mt-2 mr-1 h-0"><i
                            class="fa-solid fa-sort-up"></i></div>
                    <div *ngIf="DA?.total_earnings?.status === 'negative'" class="text-red-300 -mt-1 mr-1 h-0"><i
                            class="fa-solid fa-sort-down"></i></div>
                    <div *ngIf="DA?.total_earnings?.status === 'positive'" class="text-green-300">
                        {{DA?.buyer_chart?.percent}}</div>
                    <div *ngIf="DA?.total_earnings?.status === 'negative'" class="text-red-300">
                        {{DA?.buyer_chart?.percent}}</div>
                </div>
                <div class="text-sm text-gray-500">{{DA?.buyers?.description}}</div>
            </div>
            <div class="mt-6">
                <div *ngFor="let item of DA?.buyers?.data; index as i" class="relative">
                    <div class="flex items-center justify-center mb-1 text-left cursor-pointer"
                        popper="{{item.description}}"
                        [popperApplyClass]="'px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg tooltip dark:bg-gray-800 border-blue-800'"
                        [popperPositionFixed]="true">
                        <div class="mr-2">
                            <svg width="12" height="12">
                                <defs>
                                    <linearGradient id="Gradient{{i}}">
                                        <stop offset="0%" [attr.stop-color]="CI_COLORS[i]"></stop>
                                        <stop offset="100%" [attr.stop-color]="CI_COLORS[i+1]"></stop>
                                    </linearGradient>
                                </defs>
                                <circle cx="6" cy="6" r="5" fill="transparent" stroke-width="2"
                                    [attr.stroke]="getGradient(i)" />
                            </svg>
                        </div>
                        <div class="w-28 leading-4 text-xs">{{item?.label}}</div>
                        <div>
                            <svg height="6" width="118">
                                <defs>
                                    <linearGradient id="Gradient{{i}}">
                                        <stop offset="0%" [attr.stop-color]="CI_COLORS[i]"></stop>
                                        <stop offset="100%" [attr.stop-color]="CI_COLORS[i+1]"></stop>
                                    </linearGradient>
                                </defs>
                                <line x1="0" y1="0" x2="118" y2="0"
                                    style="stroke: black; stroke-width:6px; shape-rendering: crispEdges;" />

                                <line x1="0" y1="0" [attr.x2]="drawPercentLine(item.percent)" y2="0"
                                    [attr.stroke]="CI_COLORS[i]"
                                    style="stroke-width:6px; shape-rendering: crispEdges;" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-blueishBlack mt-6 rounded-md px-4 py-4 text-white w-full lg:w-[18rem]">
            <div class="relative shadow-md sm:rounded-lg">
                <table class="md:text-xs lg:text-xs text-left text-gray-500 dark:text-gray-400 w-full">
                    <thead class="text-gray-700 uppercase dark:text-gray-400">
                        <tr>
                            <th scope="col" class="p-2 bg-gray-50 dark:bg-gray-800 text-center">
                                {{DA?.traffic_sources?.title}}
                            </th>
                            <th scope="col" class="p-2 text-center">
                                Sold Calls
                            </th>
                            <th scope="col" class="p-2 bg-gray-50 dark:bg-gray-800 text-center">
                                Total Calls
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b border-gray-200 dark:border-gray-700"
                            *ngFor="let item of DA?.traffic_sources?.data; index as i">
                            <th scope="row"
                                class="p-3 font-medium text-gray-900 whitespace-nowrap dark:text-white flex">
                                <div class="mr-2">
                                    <svg height="14" width="14">
                                        <circle cx="7" cy="7" r="5" stroke="black" stroke-width="3"
                                            [attr.fill]="CI_COLORS[i]" />
                                    </svg>
                                </div>
                                <div>
                                    {{item.label}}
                                </div>
                            </th>
                            <td class="px-6 py-4">
                                {{item.traffic}}
                            </td>
                            <td class="px-6 py-4">
                                {{item.valume}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="flex flex-col items-center w-full">
        <div *ngIf="isFetching" class="flex flex-col items-center mt-8 mb-2">
            <div class="text-gray-400 font-awesome text-2xl">
                <div class="loader"></div>
            </div>
        </div>
        <div class="text-purpleBlue text-lg mt-10">
            TIMELINE
        </div>
        <ci-timeline (callback)="(selectedTimelineCallback($event))"></ci-timeline>
        <div class="text-gray-600 text-sm mt-2">
            <i><span>{{timelineStartDate}}</span>&nbsp;<span
                    *ngIf="timelineStartDate && timelineEndDate">to</span>&nbsp;<span>{{timelineEndDate}}</span></i>
        </div>


        <div class="flex flex-col lg:flex-row items-center justify-between">
            <div>
                <app-dashboard-graph [dashboardGraph]="dashboardGraph"></app-dashboard-graph>
            </div>
            <div class="mt-2 lg-mt-0">
                <div class="rounded-md px-4 py-4 text-white flex flex-col items-center justify-center">
                    <div class="mb-2 font-awesome text-gray-400 text-sm text-center">
                        Per Buyer Revenue
                    </div>
                    <div>
                        <svg width="120" height="120" id="per-buyer-revenue"></svg>
                    </div>
                </div>
                <div class="rounded-md px-4 py-4 text-white flex flex-col items-center justify-center">
                    <div class="mb-2 font-awesome text-gray-400 text-sm text-center">
                        Total Sold Calls
                    </div>
                    <div>
                        <svg width="120" height="120" id="net-profit-amount"></svg>
                    </div>

                </div>
                <div class="rounded-md px-4 py-4 text-white flex flex-col items-center justify-center">
                    <div class="mb-2 font-awesome text-gray-400 text-sm text-center">
                        Total Calls sent
                    </div>
                    <div>
                        <svg width="120" height="120" id="total-calls-sent-today"></svg>
                    </div>

                </div>
            </div>
        </div>
        <div class="flex flex-col lg:flex-row items-center lg:justify-between justify-center mt-28">
            <div class="flex flex-col items-center lg:mr-14 mt-2 lg:mt-0">
                <div class="border rounded-lg  border-gray-600 p-3 overflow-clip w-10 mb-3">
                    <img src="assets/img/Vector1.svg" alt="vector 1" />
                </div>
                <div class="text-purpleBlue">{{baseInfo?.b8?.title}}</div>
                <div class="text-white font-awesome text-4xl">{{baseInfo?.b8?.value}}</div>
            </div>
            <div class="flex flex-col items-center lg:mr-14 mt-2 lg:mt-0">
                <div class="border rounded-lg  border-gray-600 p-3 overflow-clip w-10 mb-3">
                    <img src="assets/img/Vector2.svg" alt="vector 1" />
                </div>
                <div class="text-purpleBlue">{{baseInfo?.b9?.title}}</div>
                <div class="text-white font-awesome text-4xl">{{baseInfo?.b9?.value}}</div>
            </div>
            <div class="flex flex-col items-center lg:mr-14 mt-2 lg:mt-0 ">
                <div class="border rounded-lg  border-gray-600 p-3 overflow-clip w-10 mb-3">
                    <img src="assets/img/Vector3.svg" alt="vector 1" />
                </div>
                <div class="text-purpleBlue">{{baseInfo?.b10?.title}}</div>
                <div class="text-white font-awesome text-4xl">{{baseInfo?.b10?.value}}</div>
            </div>
            <div class="flex flex-col items-center mt-2 lg:mt-0">
                <div class="border rounded-lg  border-gray-600 p-3 overflow-clip w-10 mb-3">
                    <img src="assets/img/Option.svg" alt="vector 1" />
                </div>
                <div class="text-purpleBlue">{{baseInfo?.b11?.title}}</div>
                <div class="text-white font-awesome text-4xl">{{baseInfo?.b11?.value}}</div>
            </div>
        </div>
    </div>
</div>