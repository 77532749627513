import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { BACK_END_ENDPOINT, getToken } from 'src/app/utils/common';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(private http: HttpClient) { }
  logout() {
    const formData = new FormData();
    formData.append('token', getToken());
    return this.http
      .post<any>(`${BACK_END_ENDPOINT}/api/logout.php`, formData);
  }
}
