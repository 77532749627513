import { Component } from '@angular/core';

@Component({
  selector: 'app-ci-footer',
  templateUrl: './ci-footer.component.html',
  styleUrls: ['./ci-footer.component.css']
})
export class CiFooterComponent {

}
