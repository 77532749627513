import { Component, OnInit } from '@angular/core';

import * as dlib from 'd3';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/services/ci-dashboard/dashboard.service';
import {
  DASHBOARD_DATA_TYPES,
  formatDate,
  validateDateRangeAndFormat,
} from 'src/app/utils/common';

@Component({
  selector: 'app-ci-dashboard',
  templateUrl: './ci-dashboard.component.html',
  styleUrls: ['./ci-dashboard.component.css'],
})
export class CiDashboardComponent implements OnInit {
  tooltip: any;
  isFetching: boolean = false;
  DA: any = {};
  timelineStartDate: string | undefined | null = null;
  timelineEndDate: string | undefined | null = null;
  selectedTimeline: string = 'ALL';
  dashboardGraph: Subject<any> = new Subject();
  openRangeFilter: boolean = false;
  baseInfo: any = {};
  CI_COLORS: Array<string> = [
    '#8AFF6C',
    '#E323FF',
    '#FFD422',
    '#984ea3',
    '#e41a1c',
    '#f9de3f',
    '#5EFF5A',
    '#FF6A2A',
    '#AE2AFF',
    '#20DFB1',
    '#FF2069',
    '#FFA63F',
    '#967BE1',
    '#0050C9',
    '#FFFFFF',
  ];
  constructor(
    private dashboardService: DashboardService,
    private toster: ToastrService
  ) {
    setTimeout(() => {
      const _window: any = window;
      document
        .querySelectorAll('[datepicker]')
        .forEach(function (datepickerEl) {
          new _window.Datepicker(datepickerEl);
        });
    }, 2000);
  }

  toggleRange() {
    this.openRangeFilter = !this.openRangeFilter;
  }

  ngOnInit() {
    this.loadDashboard(this.selectedTimeline);
    this.tooltip = dlib.select('.tooltip-bar').style('opacity', 1);
  }

  selectedTimelineCallback(event: any) {
    this.selectTimeline(event.timeline, event.dateRange);
  }

  selectTimeline(timeline: string, dateRange: any = null) {
    this.loadDashboard(timeline, dateRange);
  }

  loadDashboard(timeline: string, dateRange: any = null) {
    this.isFetching = true;
    this.dashboardService
      .getDashboardDetails(timeline, dateRange)
      .subscribe((res: any) => {
        this.renderDashboard(res);
        this.isFetching = false;
      });
  }

  renderDashboard(data: any) {
    for (let key in data) {
      this.renderAssets(data[key], data[key].type);
    }
  }

  prepareBaseInfo(info: any) {
    const _blocks = [8, 9, 10, 11];
    for (const key in info) {
      const _info = info[key];
      if (_blocks.indexOf(+_info.block) !== -1) {
        this.baseInfo[`b${_info.block}`] = {
          title: _info.title,
          value: _info.sub_title,
        };
      }
    }
  }

  renderAssets(_data: any, type: string) {
    const data = cloneDeep(_data);
    this.DA[type] = data?.series;
    console.log(this.DA, 'TES')
    if (DASHBOARD_DATA_TYPES[type] === 'total_earnings') {
      this.renderCircleChart('#total-earnings', data?.series, '%');
    }
    if (DASHBOARD_DATA_TYPES[type] === 'buyer_revenue') {
      this.renderCircleChart('#per-buyer-revenue', data?.series, '%');
    }
    if (DASHBOARD_DATA_TYPES[type] === 'profit_amount') {
      this.renderCircleChart('#net-profit-amount', data?.series, '%');
    }
    if (DASHBOARD_DATA_TYPES[type] === 'calls_sent') {
      this.renderCircleChart('#total-calls-sent-today', data?.series, '%');
    }
    if (DASHBOARD_DATA_TYPES[type] === 'buyer_chart') {
      this.renderLineChart('#buyer_chart', data?.series);
    }
    if (DASHBOARD_DATA_TYPES[type] === 'finance') {
      this.timelineStartDate = data?.startDate;
      this.timelineEndDate = data?.endDate;
      this.prepareBaseInfo(data?.series?.data);
      this.dashboardGraph.next(data?.series?.data);
    }
  }

  renderLineChart(chartID: string, asset: any) {
    const svg = dlib.select(chartID);
    svg.selectAll('*').remove();
    const width = Number(svg.attr('width'));
    const height = Number(svg.attr('height'));
    const margin = 0;
    const lineOpacity = '1';
    const data: any = asset?.data;

    const parseDate = dlib.timeParse('%Y-%m-%d');
    data.forEach(function (d: any) {
      d.values.forEach(function (d: any) {
        d.date = parseDate(d.date);
        d.price = +d.price;
      });
    });

    const xScale = dlib
      .scaleTime()
      .domain(dlib.extent(data[0]?.values, (d: any) => d.date) as any)
      .range([0, width - margin]);

    const yScale = dlib
      .scaleLinear()
      .domain([0, dlib.max(data[0].values, (d: any) => d.price) as any])
      .range([height - margin, 0]);

    const color = dlib.scaleOrdinal(dlib.schemeCategory10);

    svg
      .append('svg')
      .attr('width', width + margin + 'px')
      .attr('height', height + margin + 'px')
      .append('g')
      .attr('transform', `translate(${margin}, ${margin})`);

    const line = dlib
      .line()
      .x((d: any) => xScale(d.date))
      .y((d: any) => yScale(d.price))
      .curve(dlib.curveBasis);

    let lines = svg.append('g').attr('class', 'lines');
    // Max value observed:
    const max: any = dlib.max(data[0].values, function (d: any) {
      return +d.price;
    });

    // Set the gradient
    svg
      .append('linearGradient')
      .attr('id', 'line-gradient-0')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0)
      .attr('y1', yScale(0))
      .attr('x2', 0)
      .attr('y2', yScale(max))
      .selectAll('stop')
      .data([
        { offset: '0%', color: '#E323FF' },
        { offset: '100%', color: '#7517F8' },
      ])
      .enter()
      .append('stop')
      .attr('offset', function (d) {
        return d.offset;
      })
      .attr('stop-color', function (d) {
        return d.color;
      });

    // Set the gradient
    svg
      .append('linearGradient')
      .attr('id', 'line-gradient-1')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0)
      .attr('y1', yScale(0))
      .attr('x2', 0)
      .attr('y2', yScale(max))
      .selectAll('stop')
      .data([
        { offset: '0%', color: '#FFD422' },
        { offset: '100%', color: '#FF7D05' },
      ])
      .enter()
      .append('stop')
      .attr('offset', function (d) {
        return d.offset;
      })
      .attr('stop-color', function (d) {
        return d.color;
      });
    lines
      .selectAll('.line-group')
      .data(asset?.data)
      .enter()
      .append('g')
      .attr('class', 'line-group')

      .append('path')
      .attr('class', 'line')
      .attr('fill', 'none')
      .attr('d', (d: any) => line(d.values))
      .attr('stroke', (d, i) => {
        return 'url(#line-gradient-' + i + ')';
      })
      .style('stroke-width', '3px')
      .style('opacity', lineOpacity);
  }

  getGradient(index: number) {
    return `url(#Gradient${index})`;
  }
  drawPercentLine(percent: any) {
    return percent?.indexOf('%') !== -1
      ? percent.substr(0, percent.length - 1)
      : percent;
  }
  renderCircleChart(chartID: string, asset: any, symbol: string) {
    const svg = dlib.select(chartID);
    svg.selectAll('*').remove();
    const width = Number(svg.attr('width'));
    const height = Number(svg.attr('height'));
    const radius = Math.min(width, height) / 2;
    const g = svg
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    const color = dlib.scaleOrdinal(this.CI_COLORS);

    // Generate the pie
    const pie: any = dlib
      .pie()
      .value((d: any) => {
        return d.itemValue;
      })
      .padAngle(0.05);
    // Generate the arcs
    const arc: any = dlib
      .arc()
      .startAngle(function (d: any) {
        return d.startAngle;
      })
      .endAngle(function (d: any) {
        return d.endAngle;
      })
      .outerRadius(radius)
      .innerRadius(radius / 1.3);

    //Generate groups
    const arcs = g
      .selectAll('arc')
      .data(pie(asset?.data))
      .enter()
      .append('g')
      .on('mouseover', (e: any, d: any) => {
        this.tooltip.transition().duration(200).style('opacity', 0.9);
        this.tooltip
          .html(`${d.data.itemLabel}: <span>${d.data.itemValue}</span>`)
          .style('left', `${e.layerX}px`)
          .style('top', `${e.layerY - 50}px`);
      })
      .on('mousemove', (event: any) => {
        // console.log('mousemove ', event)
      })
      .on('mouseout', () => {
        this.tooltip.transition().duration(500).style('opacity', 0);
      })
      .attr('class', 'arc');

    //Draw arc paths
    arcs
      .append('path')
      .attr('fill', (d: any, i: any) => {
        return color(i);
      })
      .attr('d', arc);

    const _text = svg
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    const sum = dlib.sum(asset?.data, (d: any) => d.itemValue);
    _text
      .append('text')
      .attr('text-anchor', 'middle')
      .style('fill', 'white')
      .style('font-size', '12px')
      .style('font-weight', '600')
      .attr('y', '10')
      .text(symbol === '%' ? sum : '$' + sum);
  }
}
