import * as d3 from 'd3';

const dlib: any = d3;

export const computeDimensions = (selection: any) => {
  var dimensions = null;
  var node = selection.node();

  if (node instanceof SVGGraphicsElement) {
    // check if node is svg element
    dimensions = node.getBBox();
  } else {
    // else is html element
    dimensions = node.getBoundingClientRect();
  }
  return dimensions;
};

const isLocal = true;

const ENVIRONMENT = isLocal ? 'local' : 'prod';
export const BACK_END_ENDPOINT =
  ENVIRONMENT === 'local' ? '/click' : 'https://dashboard.clikmedia.tech';
export const getToken = () => {
  return localStorage.getItem(USER_TOKEN) || '';
};

export const REPORT_TYPES: Record<string, string> = {
  call_summary: 'call_summary',
  landingpage_views: 'landingpage_views',
  duration_calls: 'duration_calls',
  traffic_sources: 'traffic_sources',
  cost_traffic_sources: 'cost_traffic_sources',
  call_summary_vendors: 'call_summary_vendors',
};

export const DASHBOARD_DATA_TYPES: Record<string, string> = {
  total_earnings: 'total_earnings',
  buyers: 'buyers',
  buyer_chart: 'buyer_chart',
  traffic_sources: 'traffic_sources',
  buyer_revenue: 'buyer_revenue',
  profit_amount: 'profit_amount',
  calls_sent: 'calls_sent',
  finance: 'finance',
};

export const PAGINATE = (
  data: Array<any>,
  pageSize: number,
  pageNumber: number
) => {
  return data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

export const formatDate = (d: any) => {
  const today = new Date(d);
  const yyyy = today.getFullYear();
  let mm: any = today.getMonth() + 1; // Months start at 0!
  let dd: any = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return dd + '-' + mm + '-' + yyyy;
};

export const validateDateRangeAndFormat = (data: any, toster: any) => {
  if (data?.from_date || data.to_date) {
    if (data?.from_date && data.to_date) {
      const fDate = new Date(data.from_date).getTime();
      const tDate = new Date(data.to_date).getTime();

      if (fDate === tDate) {
        toster.error("Both date can't be same.");
        return null;
      } else if (fDate > tDate) {
        toster.error("FROM date cant't be greater than TO date.");
        return null;
      }
      return formatDate(data.from_date) + ' - ' + formatDate(data.to_date);
    } else {
      toster.error('Both From and To dates needs to be selected.');
      return null;
    }
  }
  return '';
};

const EXCLUDE_FIELDS = ['from_date', 'to_date'];

export const validateAndBuildPayload = (payload: any, toster: any) => {
  let isDateFound = false,
    searchReady = false;
  const dateRange = validateDateRangeAndFormat(payload, toster);
  if (dateRange === null) {
    return null;
  }
  const _payload: any = {};
  for (const key in payload) {
    if (EXCLUDE_FIELDS.indexOf(key) !== -1 && payload[key]) {
      isDateFound = true;
    }
    if (payload[key] && EXCLUDE_FIELDS.indexOf(key) === -1) {
      _payload[key] = payload[key];
      searchReady = true;
    }
  }
  if (!searchReady && !isDateFound) {
    toster.error('Select/Enter search value to initiate search.');
    return null;
  }
  if (isDateFound) {
    _payload['date'] = dateRange;
  }
  return _payload;
};

export const resetChartTooltip = () => {
  dlib.select('.tooltip-bar').style('opacity', 0);
};

export const USER_ROLE = 'brisk';
export const USER_TOKEN = 'brisk-token';
