<div class="px-4 py-2 bg-blac border-r-2 border-gray-800 w-72 h-full bg-gray-900">
    <div class="my-2 mb-6 border-gray-800 border-b-2 py-2">
        <h1 class="text-2xl font-awesome text-gray-400">Reports</h1>
    </div>
    <ul class="text-sm">
        <li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']" [routerLinkActiveOptions]="{ exact: true }" 
        class="mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500" [routerLinkActiveOptions]="{ exact: true }">
            <a routerLink="/statistics/reports" [routerLinkActive]="['text-white']" [routerLinkActiveOptions]="{ exact: true }"
                class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-graph-up inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z" />
                </svg>
                Overview
            </a>
        </li>
        <li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']" [routerLinkActiveOptions]="{ exact: true }" 
        class="mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
            <a routerLink="/statistics/reports/daily" [routerLinkActive]="['text-white']"
                class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-send inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                    <path
                        d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                </svg>
                Daily
            </a>
        </li>
        <li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']" [routerLinkActiveOptions]="{ exact: true }" 
        class="mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
            <a routerLink="/statistics/reports/weekly" [routerLinkActive]="['text-white']"
                class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-activity inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                </svg>
                Weekly
            </a>
        </li>
        <li [routerLinkActive]="['bg-gradient-to-r', 'from-pink-500', 'via-red-500', 'to-yellow-500', 'shadow']" [routerLinkActiveOptions]="{ exact: true }" 
        class="mb-2 rounded hover:shadow hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
            <a routerLink="/statistics/reports/monthly" [routerLinkActive]="['text-white']"
                class="inline-block w-full h-full px-3 py-2 font-awesome text-purpleBlue hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-diagram-2-fill inline-block w-6 h-6 mr-2 -mt-1" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-3 8A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1z" />
                </svg>
                Monthly
            </a>
        </li>
    </ul>
</div>