import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/ci-auth/auth-service';

@Component({
  selector: 'app-ci-sidenav',
  templateUrl: './ci-sidenav.component.html',
  styleUrls: ['./ci-sidenav.component.css'],
})
export class CiSidenavComponent {
  expand: boolean = false;

  isUser: boolean = false;
  showMobileMenu:boolean = false;

  constructor(private authService: AuthService) {
    this.isUser = authService.isUser;
  }


  expandReport(event: any) {
    this.expand = !this.expand;
  }

  onShowMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }
}
