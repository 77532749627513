import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { BACK_END_ENDPOINT, getToken } from 'src/app/utils/common';

@Injectable({
  providedIn: 'root',
})
export class StatsCountService {
  statsCount: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) {}

  listStatsCount() {
    const formData = new FormData();
    formData.append('token', getToken());
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/stat_count.php`,
      formData
    );
  }

  searchStatsCount(payload: any) {
    const formData = new FormData();

    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    formData.append('token', getToken());
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/stat_count.php`,
      formData
    );
  }

  fetchStatsCountByPageNumber(pageNumber: any, payload: any = null) {
    const formData = new FormData();
    formData.append('token', getToken());
    formData.append('page', pageNumber);

    if (payload) {
      for (const key in payload) {
        if (payload[key]) {
          formData.append(key, payload[key]);
        }
      }
    }

    this.http
      .post<any>(`${BACK_END_ENDPOINT}/api/stat_count.php`, formData)
      .subscribe((res) => {
        this.statsCount.next(res);
      });
  }
}

