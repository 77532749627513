import { Injectable } from '@angular/core';

import {
  HttpClient,
} from '@angular/common/http';
import { BACK_END_ENDPOINT, getToken } from 'src/app/utils/common';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CostSettingsService {
  costSettings: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) {}

  loadData() {
    const formData = new FormData();
    formData.append('token', getToken());
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/list_cost_settings.php`,
      formData
    );
  }
  updateCostSettings(payload: any) {
    const formData = new FormData();
    formData.append('token', getToken());
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    return this.http.post<any>(
      `${BACK_END_ENDPOINT}/api/update_cost_settings.php`,
      formData
    );
  }

  fetchCostSettingsByPageNumber(pageNumber: any) {
    const formData = new FormData();
    formData.append('token', getToken());
    formData.append('page', pageNumber);

    this.http
      .post<any>(`${BACK_END_ENDPOINT}/api/list_cost_settings.php`, formData)
      .subscribe((res) => {
        this.costSettings.next(res);
      });
  }
}
