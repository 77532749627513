import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BACK_END_ENDPOINT, getToken, USER_TOKEN } from 'src/app/utils/common';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  accountInfo: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) { }

  fetchAccountDetails() {
    this.getAccountDetails().subscribe(res => {
      if(res.status) {
        this.accountInfo.next(res.data);
      } else {
        localStorage.removeItem(USER_TOKEN);
      }
    });
  }

  getAccountDetails() {
    const formData = new FormData();
    formData.append('token', getToken());
    return this.http
      .post<any>(`${BACK_END_ENDPOINT}/api/account.php`, formData);
  }

  updateProfile(profile: any) {
    const formData = new FormData();
    for (const key in profile) {
      formData.append(key, profile[key]);
    }
    formData.append('token', getToken());
    return this.http
      .post<any>(`${BACK_END_ENDPOINT}/api/update_profile.php`, formData);
  }

  changePassword(data: any) {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    formData.append('token', getToken());
    return this.http
      .post<any>(`${BACK_END_ENDPOINT}/api/change_password.php`, formData);
  }

}
