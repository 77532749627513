import { Component } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { BuyerService } from 'src/app/services/buyer.service';
import { CostSettingsService } from 'src/app/services/ci-cost-settings/cost-settings.service';
import { formatDate, PAGINATE } from 'src/app/utils/common';

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalRecords: 0,
  data: [],
};

@Component({
  selector: 'app-ci-cost-settings',
  templateUrl: './ci-cost-settings.component.html',
  styleUrls: ['./ci-cost-settings.component.css'],
})
export class CiCostSettingsComponent {
  totalRecordsCount: Subject<any> = new Subject();
  changedData: any = {};
  costSettingsList: Array<any> = [];

  totalRecords: number = 0;
  recordsPerPage: number = 10;
  activePage: number = 1;
  itemsToDisplay: Array<any> = [];
  buyerList: Array<any> = [];
  isFetching: boolean = false;
  isUpdatingID: string = '';

  CURRENT_API_CALL_STATUS: any = initialState;

  constructor(
    private costSettingsService: CostSettingsService,
    private buyerService: BuyerService,
    private toster: ToastrService
  ) {
    this.isFetching = true;
    this.costSettingsService.fetchCostSettingsByPageNumber(1);
    this.costSettingsService.costSettings.subscribe((res) => {
      if (res.status) {
        this.CURRENT_API_CALL_STATUS = {
          currentPage: res.currentPage,
          totalPages: res.totalPages,
          totalRecords: res.totalRecords,
          data: res.data,
        };

        const newData = res.data.map((item: any) => {
          return Object.assign({}, item, {
            ...(!item?.sold && item.show_field === 'dateandbuyer' && { sold: '0.00' }),
          });
        });
        this.costSettingsList = [...this.costSettingsList, ...newData];
        this.totalRecords = this.costSettingsList.length;
        this.buildRecords(this.activePage);
        setTimeout(() => {
          this.totalRecordsCount.next(this.totalRecords);
        });
      } else {
        this.costSettingsList = [];
        this.totalRecords = this.costSettingsList.length;
        this.activePage = 1;
        this.buildRecords(1);
        setTimeout(() => {
          this.totalRecordsCount.next(this.totalRecords);
        });
      }
      this.isFetching = false;
    });
    this.buyerService.fetchBuyerList(1);
    this.buyerService.buyerInfo.subscribe((res) => {
      if (res.status) {
        this.buyerList = res.data;
      }
    });
  }

  fetchCostSettingsList() {
    this.isFetching = true;
    this.costSettingsService.loadData().subscribe((res) => {
      if (res.status) {
        this.costSettingsList = res.data;
        this.totalRecords = this.costSettingsList.length;
        this.buildRecords(1);
      } else {
        this.toster.error('Failed to fetch cost settings list');
      }
      this.isFetching = false;
    });
  }

  updateCost(_data: any) {
    const data = cloneDeep(_data);
    let _date: any = document.getElementById('date-'+data.id);
    if (_date) {
      _date = formatDate(_date.value);
      data['date'] = _date;
    }
    if (data.buyer) {
      data['buyer'] = data.buyer;
    }
    if (data.show_field === 'dateandbuyer' || data.show_field === 'date') {
      delete data.percentage;
    }
    if (data?.show_field?.indexOf('buyer') !== -1 && !data?.buyer) {
      this.toster.error('Please select Buyer');
      return;
    }
    delete data.status;
    delete data.traffic_source;
    delete data.show_field;
    if (data?.date && data?.date?.indexOf('NaN') !== -1) {
      this.toster.error('Please select Date');
      return;
    }
    this.isUpdatingID = data.id;
    this.costSettingsService.updateCostSettings(data).subscribe({
      next: (res) => {
        if (res.status) {
          this.toster.success('Cost settings udpated successfully');
        } else {
          this.toster.error(res.message);
        }
        this.isUpdatingID = '';
      },
      error: (err) => {
        this.toster.error(err.message);
        this.isUpdatingID = '';
      },
    });

    
  }

  buildRecords(pageNumber: number) {
    this.itemsToDisplay = PAGINATE(
      this.costSettingsList,
      this.recordsPerPage,
      pageNumber
    );
    setTimeout(() => {
      const _window: any = window;
      document
        .querySelectorAll('[datepicker]')
        .forEach(function (datepickerEl) {
          new _window.Datepicker(datepickerEl);
        });
    }, 1000);
  }

  fetchCostSettingsByPageNumber(pageNumber: number) {
    if (Number(pageNumber) <= this.CURRENT_API_CALL_STATUS.totalPages) {
      this.isFetching = true;
      this.costSettingsService.fetchCostSettingsByPageNumber(
        Number(pageNumber) + 1
      );
    }
  }

  pageEvent(event: any) {
    if (event.isFetchMore) {
      this.fetchCostSettingsByPageNumber(
        this.CURRENT_API_CALL_STATUS.currentPage
      );
    }
    this.activePage = event.pageNumber;
    this.recordsPerPage = event?.pageSize;
    this.buildRecords(event?.pageNumber);
  }
}
