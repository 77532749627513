import { Component } from '@angular/core';
import { AccountService } from 'src/app/services/ci-account/account.service';
import { AuthService } from 'src/app/services/ci-auth/auth-service';
import { USER_TOKEN } from 'src/app/utils/common';

@Component({
  selector: 'app-ci-navbar',
  templateUrl: './ci-navbar.component.html',
  styleUrls: ['./ci-navbar.component.css']
})
export class CiNavbarComponent {

  isAccount: boolean = false;
  showMobileMenu: boolean = false;
  account: any = {};
  constructor(private authService: AuthService, private accountService: AccountService) {
    this.authService.userInfo.subscribe((res) => {
      if (res.status) {
        this.accountService.fetchAccountDetails();
      }
    });
    this.accountService.accountInfo.subscribe(account => {
      this.account = account;
      this.isAccount = Object.keys(account).length > 0;
    });
    if (localStorage.getItem(USER_TOKEN)) {
      this.accountService.fetchAccountDetails();
    }
  }
  onShowMobileMenu() {
    console.log("CLICK")
    this.showMobileMenu = !this.showMobileMenu;
    console.log(this.showMobileMenu, 'asd')
  }
}
