import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { StatisticsService } from 'src/app/services/ci-statistics/statistics.service';
import { REPORT_TYPES } from 'src/app/utils/common';
import { drawBarChart, drawChart } from 'src/app/utils/drawChart';

@Component({
  selector: 'app-ci-statistics',
  templateUrl: './ci-statistics.component.html',
  styleUrls: ['./ci-statistics.component.css']
})
export class CiStatisticsComponent implements OnInit {
  reportTypes: any = REPORT_TYPES;
  isFetching: boolean = false;
  info: any = {};
  constructor(private statisticsService: StatisticsService) { }
  ngOnInit() {
    /*
    const _el: any = d3.select("#right-container");
    const containerWidth = _el.node().clientWidth - 80;
    let container = d3.select("#bar-chart-1");
    container.attr("width", containerWidth);
    drawChart(
      container,
      ["A1", "B1", "C1"],
      "Call Counts"
    );

    container = d3.select("#bar-chart-2");
    container.attr("width", containerWidth);
    drawChart(
      container,
      ["A1", "B1"],
      "View Counts"
    );

    container = d3.select("#bar-chart-3");
    container.attr("width", containerWidth);
    drawChart(container, ["A1", "B1", "C1", "D1", "E1"], "Call Counts");

    container = d3.select("#bar-chart-4");
    container.attr("width", containerWidth);
    drawChart(
      container,
      [
        "A1", "B1", "C1", "D1"
      ],
      "Call Counts"
    );

    container = d3.select("#bar-chart-5");
    container.attr("width", containerWidth);
    drawChart(
      container,
      [
        "A1", "B1", "C1", "D1"
      ],
      "Call Costs"
    );

    container = d3.select("#bar-chart-6");
    container.attr("width", containerWidth);
    drawChart(
      container,
      [
        "A1", "B1", "C1", "D1", "E1", "F1", "G1"

      ],
      "Call Counts"
    );
      */
    this.statisticReports();
  }

  buildReports(data: any) {
    this.isFetching = false;
    const _el: any = d3.select("#right-container");
    const containerWidth = _el.node().clientWidth - 80;
    for (const key in data) {
      const report = data[key];

      if (report.status && REPORT_TYPES[report.type]) {
        const container = d3.select(`#${REPORT_TYPES[report.type]}`);
        if (container && container.size() !== 0) {
          container.attr("width", containerWidth);
          drawBarChart(
            container,
            report.categories.split(',').map((item: string) => item.replaceAll("'", '').trim()),
            report.series,
          );
        }
      }
    }
  }

  statisticReports() {
    this.isFetching = true;
    this.statisticsService.statisticReports().subscribe(res => {
      this.info = res;
      this.buildReports(res);
    });
  }
}
