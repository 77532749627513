import { Component, Output, Input, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { formatDate, validateDateRangeAndFormat } from 'src/app/utils/common';

@Component({
  selector: 'ci-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css'],
})
export class TimelineComponent {
  @Output() callback = new EventEmitter<any>();
  @Input() isReport: boolean = false;
  selectedTimeline: string = 'ALL';
  openRangeFilter: boolean = false;
  baseInfo: any = {};

  constructor(private toster: ToastrService) {}

  resetCustomRangeFilter() {
    const _start: any = document.getElementById('start');
    const _end: any = document.getElementById('end');
    _start.value = '';
    _end.value = '';
    this.selectTimeline('ALL');
  }

  selectTimeline(timeline: string, dateRange: any = null) {
    if (timeline === 'custom_range') {
      this.resetCustomRangeFilter();
    }
    this.selectedTimeline = timeline;
    this.callback.emit({
      timeline: this.selectedTimeline,
      dateRange,
    });
  }

  toggleRange() {
    this.openRangeFilter = !this.openRangeFilter;
  }

  onCustomFilter(timeline: string, flag: boolean) {
    this.openRangeFilter = false;
    let _start: any = document.getElementById('start');
    let _end: any = document.getElementById('end');
    const minDate = new Date('2024-01-01');
  
    if (!flag) {
      this.resetCustomRangeFilter();
      return;
    }
  
    let _date: any;
    if (
      _start?.value &&
      _end?.value &&
      _start.value?.indexOf('nan') == -1 &&
      _end.value?.indexOf('nan') == -1
    ) {
      const startDate = new Date(_start.value);
      const endDate = new Date(_end.value);
  
      // Check if the selected dates are after the minimum date
      if (startDate < minDate || endDate < minDate) {
        this.toster.error('Please select a date after 1 January 2024');
        return;
      }
  
      _date = validateDateRangeAndFormat(
        {
          from_date: _start.value,
          to_date: _end.value,
        },
        this.toster
      );
  
      if (!_date) {
        return;
      }
    } else {
      // Handle single date selections for start or end date
      if (_start.value && _start.value?.indexOf('nan') == -1) {
        _start = formatDate(_start.value);
        const startDate = new Date(_start);
  
        if (startDate < minDate) {
          this.toster.error('Please select a start date after 1 January 2024');
          return;
        }
  
        _date = _start + ' - ' + _start;
      } else if (_end.value && _end.value?.indexOf('nan') == -1) {
        _end = formatDate(_end.value);
        const endDate = new Date(_end);
  
        if (endDate < minDate) {
          this.toster.error('Please select an end date after 1 January 2024');
          return;
        }
  
        _date = _end + ' - ' + _end;
      }
    }
  
    if (!_date) {
      this.toster.error('Please select a range date');
      return;
    }
  
    this.selectTimeline(timeline, _date);
  }
}
