import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BACK_END_ENDPOINT, getToken } from '../utils/common';

@Injectable({
  providedIn: 'root',
})
export class BuyerService {
  buyerInfo: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) {}

  fetchBuyerList(pageNumber: any) {
    const formData = new FormData();
    formData.append('token', getToken());
    formData.append('page', pageNumber);

    this.http
      .post<any>(`${BACK_END_ENDPOINT}/api/list_buyer.php`, formData)
      .subscribe((res) => {
        this.buyerInfo.next(res);
      });
  }
}
