import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from 'src/app/services/ci-reports/reports.service';
import { REPORT_TYPES } from 'src/app/utils/common';
import { drawSingleBarChart } from 'src/app/utils/drawChart';

@Component({
  selector: 'app-ci-report-daily',
  templateUrl: './ci-report-daily.component.html',
  styleUrls: ['./ci-report-daily.component.css'],
})
export class CiReportDailyComponent implements OnInit {
  reportTypes: any = REPORT_TYPES;
  isFetching: boolean = false;
  info: any = {};
  constructor(
    private reportsService: ReportsService,
    private toster: ToastrService
  ) {
    setTimeout(() => {
      const _window: any = window;
      document
        .querySelectorAll('[datepicker]')
        .forEach(function (datepickerEl) {
          new _window.Datepicker(datepickerEl);
        });
    }, 2000);
  }

  ngOnInit(): void {
    this.listReports('ALL');
  }

  buildReports(data: any) {
    this.isFetching = false;
    const _el: any = d3.select('#right-container');
    const containerWidth = _el.node().clientWidth - 80;
    for (const key in data) {
      const report = data[key];

      if (report.status && REPORT_TYPES[report.type]) {
        const _ID = `#${REPORT_TYPES[report.type]}`;
        const container = d3.select(_ID);
        if (container && container.size() !== 0) {
          container.attr('width', containerWidth);
          drawSingleBarChart(container, report.series);
        }
      }
    }
  }

  listReports(timeline: string, dateRange: any = null) {
    this.isFetching = true;
    this.reportsService.dailyReports(timeline, dateRange).subscribe((res) => {
      setTimeout(() => {
        this.info = res;
        this.buildReports(res);
      }, 1000);
    });
  }

  selectedTimelineCallback(event: any) {
    this.listReports(event.timeline, event.dateRange);
  }
}
