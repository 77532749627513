<div>
    <div class="flex items-center text-white cursor-pointer">
        <!--<div class="mr-4 text-sm"
            [ngClass]="selectedTimeline === 'Yesterday' ? 'mr-4 text-sm rounded-full bg-blue-800 py-2 px-4' : 'mr-4 text-sm'"
            (click)="selectTimeline('Yesterday')">Yesterday</div>-->
        <div *ngIf="!isReport" class="mr-4 text-sm" 
            [ngClass]="selectedTimeline === '1W' ? 'mr-4 text-sm rounded-full bg-blue-800 py-2 px-4' : 'mr-4 text-sm'"
            (click)="selectTimeline('1W')">1W</div>
        <div *ngIf="!isReport" class="mr-4 text-sm"
            [ngClass]="selectedTimeline === '1M' ? 'mr-4 text-sm rounded-full bg-blue-800 py-2 px-4' : 'mr-4 text-sm'"
            (click)="selectTimeline('1M')">1M</div>
        <div *ngIf="!isReport" []="selectedTimeline === '3M' ? 'mr-4 text-sm rounded-full bg-blue-800 py-2 px-4' : 'mr-4 text-sm'"
            (click)="selectTimeline('3M')">3M</div>
        <div *ngIf="!isReport" [ngClass]="selectedTimeline === '1Y' ? 'mr-4 text-sm rounded-full bg-blue-800 py-2 px-4' : 'mr-4 text-sm'"
            (click)="selectTimeline('1Y')">1Y</div>
        <div *ngIf="!isReport" [ngClass]="selectedTimeline === 'ALL' ? 'mr-2 text-sm rounded-full bg-blue-800 py-2 px-4' : 'mr-2 text-sm'"
            (click)="selectTimeline('ALL')">ALL</div>
        <div class="mr-4 text-sm relative">
            <button title="Custom Range" (click)="toggleRange()"
                [ngClass]="[selectedTimeline === 'custom_range' ? 'dark:bg-blue-800' : 'dark:bg-gray-900']"
                class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:hover:bg-gray-800 dark:focus:ring-gray-600"
                type="button">
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z">
                    </path>
                </svg>
            </button>
            <!-- Custom Range menu -->
            <div [ngClass]="[openRangeFilter ? 'visible' : 'invisible']"
                class="absolute inset-y-12 -inset-x-16 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-48 h-48 dark:bg-gray-900 dark:divide-gray-600">
                <div class="flex items-center flex-col p-3">
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg aria-hidden="openRangeFilter ? true : false"
                                class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </div>
                        <input id="start" name="start" type="text" datepicker
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Select date start">
                    </div>
                    <span class="mx-4 text-gray-500">to</span>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </div>
                        <input id="end" name="end" type="text" datepicker
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Select date end">
                    </div>
                </div>
                <div class="p-2">
                    <button type="button" (click)="onCustomFilter('custom_range', true)"
                        class=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Submit</button>&nbsp;
                    <button type="button" (click)="onCustomFilter('custom_range', false)"
                        class=" text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                        Reset</button>
                </div>
            </div>
        </div>
    </div>
</div>