import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IUserLogin } from 'src/app/ci-types/IUser';
import { BACK_END_ENDPOINT, USER_ROLE, USER_TOKEN } from 'src/app/utils/common';
import { Subject } from 'rxjs';
import { EncryptDecryptService } from '../encrypt-decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userInfo: Subject<any> = new Subject<any>();
  private authToken: string = '';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(
    private http: HttpClient,
    private toster: ToastrService,
    private router: Router,
    private encryptDecryptService: EncryptDecryptService
  ) {}
  signIn(payload: IUserLogin) {
    const formData = new FormData();
    formData.append('username', payload.username);
    formData.append('password', payload.password);
    return this.http
      .post<any>(`${BACK_END_ENDPOINT}/api/login.php`, formData)
      .subscribe((res: any) => {
        if (res.status) {
          this.authToken = res.token;
          const _role = this.encryptDecryptService.encryptUsingAES256(res.role);
          localStorage.setItem(USER_TOKEN, this.authToken);
          localStorage.setItem(USER_ROLE, _role);
          this.toster.success('Login successful');
        } else {
          this.toster.error(res.message);
        }
        this.userInfo.next(res);
      });
  }

  logout() {
    this.authToken = '';
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(USER_ROLE);
  }

  public isAuthenticated(): boolean {
    const token: any = localStorage.getItem(USER_TOKEN);
    return token && token.trim() !== '';
  }

  public set token(value: string) {
    this.authToken = value;
  }

  public get token() {
    return this.authToken;
  }

  public get role() {
    return this.encryptDecryptService.decryptUsingAES256(
      localStorage.getItem(USER_ROLE) || ''
    );
  }

  public get isUser() {
    return this.role?.toLowerCase() === 'user';
  }
  public get isAdmin() {
    return this.role?.toLowerCase() === 'admin';
  }
}
