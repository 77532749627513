<div class="w-[90vw] lg:w-[55vw]">
    <svg id="main-graph" width="100%" height="45%" viewBox="0 0 1247 398" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M508 60C315.687 60 395.652 180.5 204 180.5" stroke="url(#paint0_linear_478_13286)" stroke-opacity="0.7"
            stroke-width="42.1095" />
        <path d="M508 218C367.747 218 406.118 244.5 205 244.5" stroke="url(#paint1_linear_478_13286)"
            stroke-opacity="0.3" stroke-width="42.1095" />
        <path d="M208 214C410.673 214 304.385 341 513 341" stroke="url(#paint2_linear_478_13286)" stroke-opacity="0.5"
            stroke-width="42.1095" />
        <path d="M1032 36C869.382 36 890.059 36 728 36" stroke="url(#paint3_linear_478_13286)" stroke-opacity="0.5"
            stroke-width="42.1095" />
        <path d="M1032 70.5C869.382 70.5 890.059 176.5 728 176.5" stroke="url(#paint4_linear_478_13286)"
            stroke-opacity="0.7" stroke-width="42.1095" />
        <path d="M1032 183C869.382 183 890.059 360.5 728 360.5" stroke="url(#paint5_linear_478_13286)"
            stroke-opacity="0.7" stroke-width="42.1095" />
        <path d="M1032 219.5C869.382 219.5 890.059 81 728 81" stroke="url(#paint6_linear_478_13286)"
            stroke-opacity="0.3" stroke-width="42.1095" />
        <path d="M1032 358C869.382 358 890.059 321.5 728 321.5" stroke="url(#paint7_linear_478_13286)"
            stroke-opacity="0.3" stroke-width="42.1095" />
        <g class="block-1">
            <rect y="136.5" width="219" height="151" rx="10" fill="url(#paint8_linear_478_13286)" />
        </g>
        <path
            d="M34 176.5H54V178.5H34V176.5ZM34 162.5L39 166L44 159.5L49 166L54 162.5V174.5H34V162.5ZM36 166.341V172.5H52V166.341L48.58 168.735L44 162.78L39.42 168.735L36 166.34V166.341Z"
            fill="white" />

        <g class="block-2">
            <rect x="507" width="226" height="112" rx="10" fill="url(#paint9_linear_478_13286)" />
        </g>
        <g class="block-3">
            <rect x="507" y="143" width="226" height="112" rx="10" fill="url(#paint10_linear_478_13286)" />
        </g>

        <g class="block-4">
            <rect x="507" y="286" width="226" height="112" rx="10" fill="url(#paint11_linear_478_13286)" />
        </g>
        <g class="block-5">
            <rect x="1021" width="226" height="112" rx="10" fill="url(#paint12_linear_478_13286)" />
        </g>
        <g class="block-6">
            <rect x="1021" y="143" width="226" height="112" rx="10" fill="url(#paint13_linear_478_13286)" />
        </g>
        <g class="block-7">
            <rect x="1021" y="286" width="226" height="112" rx="10" fill="url(#paint14_linear_478_13286)" />
        </g>

        <defs>
            <linearGradient id="paint0_linear_478_13286" x1="446.405" y1="59.6056" x2="376.835" y2="260.894"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#E94578" />
                <stop offset="0.416667" stop-color="#36B7F4" />
            </linearGradient>
            <linearGradient id="paint1_linear_478_13286" x1="527.144" y1="127.796" x2="179.096" y2="145.511"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#E94578" />
                <stop offset="0.416667" stop-color="#36B7F4" />
            </linearGradient>
            <linearGradient id="paint2_linear_478_13286" x1="287.756" y1="213.172" x2="359.935" y2="453.464"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.223958" stop-color="#36B7F4" />
                <stop offset="1" stop-color="#E94578" />
            </linearGradient>
            <linearGradient id="paint3_linear_478_13286" x1="976.004" y1="3.07336e+14" x2="694.713" y2="3.07336e+14"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#895BED" />
                <stop offset="1" stop-color="#E94578" />
            </linearGradient>
            <linearGradient id="paint4_linear_478_13286" x1="976.004" y1="178.244" x2="771.377" y2="303.493"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#9265F9" />
                <stop offset="0.713542" stop-color="#E94578" />
            </linearGradient>
            <linearGradient id="paint5_linear_478_13286" x1="976.004" y1="249.954" x2="770.334" y2="374.665"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#8D60F3" />
                <stop offset="0.713542" stop-color="#E94578" />
            </linearGradient>
            <linearGradient id="paint6_linear_478_13286" x1="976.004" y1="116.534" x2="770.057" y2="241.101"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#8D5FF2" />
                <stop offset="0.713542" stop-color="#E94578" />
            </linearGradient>
            <linearGradient id="paint7_linear_478_13286" x1="985.059" y1="435.027" x2="479.437" y2="499.784"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#8759EA" />
                <stop offset="0.380839" stop-color="#E94578" />
            </linearGradient>
            <linearGradient id="paint8_linear_478_13286" x1="4.99999" y1="287.5" x2="218.794" y2="146.01"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#1586FF" />
                <stop offset="1" stop-color="#36BAF4" />
            </linearGradient>
            <linearGradient id="paint9_linear_478_13286" x1="525.935" y1="112" x2="710.779" y2="-24.0756"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#E8475C" />
                <stop offset="1" stop-color="#E9457B" />
            </linearGradient>
            <linearGradient id="paint10_linear_478_13286" x1="525.935" y1="255" x2="710.779" y2="118.924"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#E8475C" />
                <stop offset="1" stop-color="#E9457B" />
            </linearGradient>
            <linearGradient id="paint11_linear_478_13286" x1="525.935" y1="398" x2="710.779" y2="261.924"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#E8475C" />
                <stop offset="1" stop-color="#E9457B" />
            </linearGradient>
            <linearGradient id="paint12_linear_478_13286" x1="1039.94" y1="112" x2="1224.78" y2="-24.0756"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#7A4BD8" />
                <stop offset="1" stop-color="#5226FF" />
            </linearGradient>
            <linearGradient id="paint13_linear_478_13286" x1="1039.94" y1="255" x2="1224.78" y2="118.924"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#7A4BD8" />
                <stop offset="1" stop-color="#5226FF" />
            </linearGradient>
            <linearGradient id="paint14_linear_478_13286" x1="1039.94" y1="398" x2="1224.78" y2="261.924"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#7A4BD8" />
                <stop offset="1" stop-color="#5226FF" />
            </linearGradient>
            <clipPath id="clip0_478_13286">
                <rect class="block-8" width="155" height="48" fill="white" transform="translate(32 219.5)" />
            </clipPath>
        </defs>
    </svg>
</div>