import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { CallDataArchiveService } from 'src/app/services/ci-call-data-archive/call-data-archive.service';
import { CallDataService } from 'src/app/services/ci-call-data/call-data.service';
import { PAGINATE, validateAndBuildPayload } from 'src/app/utils/common';

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalRecords: 0,
  data: [],
};

const initialFormState = {
  phone: '',
  traffic_source: '',
  call_duration: '',
  from_date: '',
  to_date: '',
};

@Component({
  selector: 'app-ci-call-data-archive',
  templateUrl: './ci-call-data-archive.component.html',
  styleUrls: ['./ci-call-data-archive.component.css'],
})
export class CiCallDataArchiveComponent implements OnInit {
  totalRecordsCount: Subject<any> = new Subject();
  callDuration: Array<any> = [];
  trafficSources: Array<any> = [];
  callDetailsArchive: Array<any> = [];

  totalRecords: number = 0;
  recordsPerPage: number = 5;
  activePage: number = 1;
  itemsToDisplay: Array<any> = [];

  isSearching: boolean = false;

  formSearch: FormGroup = new FormGroup({
    phone: new FormControl(''),
    traffic_source: new FormControl(''),
    call_duration: new FormControl(''),
    from_date: new FormControl(''),
    to_date: new FormControl(''),
  });

  infoModal: boolean = false;
  infoData: any = {};

  searchPayload: any = null;
  CURRENT_API_CALL_STATUS: any = initialState;
  isFetching: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private callDataArchiveService: CallDataArchiveService,
    private toster: ToastrService
  ) {
    this.isFetching = true;
    this.callDataArchiveService.fetchCallDetailsArchiveByPageNumber(
      1,
      this.searchPayload
    );
    this.callDataArchiveService.callDetailsArchive.subscribe((res) => {
      if (res.status) {
        this.CURRENT_API_CALL_STATUS = {
          currentPage: res.currentPage,
          totalPages: res.totalPages,
          totalRecords: res.totalRecords,
          data: res.data,
        };
        this.callDetailsArchive = [...this.callDetailsArchive, ...res.data];
        this.listCallDetailsArchive();
      } else {
        this.resetDataState(false);
      }
      this.isSearching = false;
      this.isFetching = false;
    });
  }

  ngOnInit(): void {
    this.listCallDuration();
    this.listTrafficSources();
  }

  showInfo(event: any, data: any, flag: boolean) {
    event.preventDefault();
    this.infoModal = flag;
    if (!flag) {
      this.infoData = {};
      return;
    }
    this.infoData = data;
  }

  listCallDuration() {
    this.callDataArchiveService.listCallDuration().subscribe((res) => {
      if (res.status) {
        this.callDuration = res.data;
      } else {
        this.toster.error('Failed to call duration');
      }
    });
  }

  listTrafficSources() {
    this.callDataArchiveService.listTrafficSources().subscribe((res) => {
      if (res.status) {
        this.trafficSources = res.data;
      } else {
        this.toster.error('Failed to fetchtraffic sousrce');
      }
    });
  }

  listCallDetailsArchive() {
    this.totalRecords = this.callDetailsArchive.length;

    this.buildRecords(this.activePage);
    setTimeout(() => {
      this.totalRecordsCount.next(this.totalRecords);
    });
  }

  fetchCallDetailsArchiveByPageNumber(pageNumber: number) {
    if (Number(pageNumber) <= this.CURRENT_API_CALL_STATUS.totalPages) {
      this.isFetching = true;
      this.callDataArchiveService.fetchCallDetailsArchiveByPageNumber(
        Number(pageNumber) + 1,
        this.searchPayload
      );
    }
  }

  onSearch() {
    const payload = validateAndBuildPayload(this.formSearch.value, this.toster);
    if (!payload) {
      return;
    }
    this.resetDataState(false);
    this.searchPayload = payload;
    this.isSearching = true;
    this.fetchCallDetailsArchiveByPageNumber(0);
  }

  resetDataState(flag: boolean = true) {
    this.isSearching = false;
    this.searchPayload = null;
    this.CURRENT_API_CALL_STATUS = initialState;
    this.activePage = 1;
    this.totalRecords = 0;
    this.callDetailsArchive = [];
    this.itemsToDisplay = [];
    if (flag) {
      this.callDataArchiveService.fetchCallDetailsArchiveByPageNumber(1);
    }
  }

  onSearchReset() {
    this.formSearch.patchValue(initialFormState);
    this.resetDataState();
  }

  buildRecords(pageNumber: number) {
    this.itemsToDisplay = PAGINATE(
      this.callDetailsArchive,
      this.recordsPerPage,
      pageNumber
    );
  }

  pageEvent(event: any) {
    if (event.isFetchMore) {
      this.fetchCallDetailsArchiveByPageNumber(
        this.CURRENT_API_CALL_STATUS.currentPage
      );
    }
    this.activePage = event.pageNumber;
    this.recordsPerPage = event?.pageSize;
    this.buildRecords(event?.pageNumber);
  }
}
