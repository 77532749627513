import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { CallDataService } from 'src/app/services/ci-call-data/call-data.service';
import { PAGINATE, validateAndBuildPayload } from 'src/app/utils/common';

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalRecords: 0,
  data: [],
};

const initialFormState = {
  phone: '',
  traffic_source: '',
  call_duration: '',
  from_date: '',
  to_date: '',
};

@Component({
  selector: 'app-ci-call-data',
  templateUrl: './ci-call-data.component.html',
  styleUrls: ['./ci-call-data.component.css'],
})
export class CiCallDataComponent implements OnInit {
  totalRecordsCount: Subject<any> = new Subject();
  callDuration: Array<any> = [];
  trafficSources: Array<any> = [];
  callDetails: Array<any> = [];

  totalRecords: number = 0;
  recordsPerPage: number = 5;
  activePage: number = 1;
  itemsToDisplay: Array<any> = [];

  isSearching: boolean = false;
  isFetching: boolean = false;

  CERT_TYPE: Record<string, string> = {
    NOT_CLAIMED: 'NOT_CLAIMED',
    CLAIMED: 'CLAIMED',
    BOTH: 'BOTH',
  };

  formSearch: FormGroup = new FormGroup({
    phone: new FormControl(''),
    traffic_source: new FormControl(''),
    call_duration: new FormControl(''),
    from_date: new FormControl(''),
    to_date: new FormControl(''),
  });

  searchPayload: any = null;

  infoModal: boolean = false;
  infoData: any = {};

  CURRENT_API_CALL_STATUS: any = initialState;

  constructor(
    private formBuilder: FormBuilder,
    private callDataService: CallDataService,
    private toster: ToastrService
  ) {
    this.isFetching = true;
    this.callDataService.fetchCallDetailsByPageNumber(1, this.searchPayload);
    this.callDataService.callDetails.subscribe((res) => {
      if (res.status) {
        this.CURRENT_API_CALL_STATUS = {
          currentPage: res.currentPage,
          totalPages: res.totalPages,
          totalRecords: res.totalRecords,
          data: res.data,
        };
        this.callDetails = [...this.callDetails, ...res.data];
        this.listCallDetails();
      } else {
        this.resetDataState(false);
      }
      this.isSearching = false;
      this.isFetching = false;
    });
  }

  getCertType(cert: any, claimedCert: any): string {
    if (cert && claimedCert) {
      return this.CERT_TYPE['NOT_CLAIMED'];
    }
    if (cert) {
      return this.CERT_TYPE['NOT_CLAIMED'];
    }
    if (claimedCert) {
      return this.CERT_TYPE['CLAIMED'];
    }
    return '';
  }

  fetchCallDetailsByPageNumber(pageNumber: number) {
    if (Number(pageNumber) <= this.CURRENT_API_CALL_STATUS.totalPages) {
      this.isFetching = true;
      this.callDataService.fetchCallDetailsByPageNumber(
        Number(pageNumber) + 1,
        this.searchPayload
      );
    }
  }

  ngOnInit(): void {
    this.listCallDuration();
    this.listTrafficSources();
  }

  showInfo(event: any, data: any, flag: boolean) {
    event.preventDefault();
    this.infoModal = flag;
    if (!flag) {
      this.infoData = {};
      return;
    }
    this.infoData = data;
  }

  listCallDuration() {
    this.callDataService.listCallDuration().subscribe((res) => {
      if (res.status) {
        this.callDuration = res.data;
      } else {
        this.toster.error('Failed to call duration');
      }
    });
  }

  listTrafficSources() {
    this.callDataService.listTrafficSources().subscribe((res) => {
      if (res.status) {
        this.trafficSources = res.data;
      } else {
        this.toster.error('Failed to fetchtraffic sousrce');
      }
    });
  }

  listCallDetails() {
    this.totalRecords = this.callDetails.length;

    this.buildRecords(this.activePage);
    setTimeout(() => {
      this.totalRecordsCount.next(this.totalRecords);
    });
  }

  onSearch() {
    const payload = validateAndBuildPayload(this.formSearch.value, this.toster);
    if (!payload) {
      return;
    }
    this.resetDataState(false);
    this.searchPayload = payload;
    this.isSearching = true;
    this.fetchCallDetailsByPageNumber(0);
  }

  resetDataState(flag: boolean = true) {
    this.isSearching = false;
    this.searchPayload = null;
    this.CURRENT_API_CALL_STATUS = initialState;
    this.activePage = 1;
    this.totalRecords = 0;
    this.callDetails = [];
    this.itemsToDisplay = [];
    if (flag) {
      this.callDataService.fetchCallDetailsByPageNumber(1);
    }
  }

  onSearchReset() {
    this.formSearch.patchValue(initialFormState);
    this.resetDataState();
  }

  buildRecords(pageNumber: number) {
    this.itemsToDisplay = PAGINATE(
      this.callDetails,
      this.recordsPerPage,
      pageNumber
    );
  }

  pageEvent(event: any) {
    if (event.isFetchMore) {
      this.fetchCallDetailsByPageNumber(
        this.CURRENT_API_CALL_STATUS.currentPage
      );
    }
    this.activePage = event.pageNumber;
    this.recordsPerPage = event?.pageSize;
    this.buildRecords(event?.pageNumber);
  }
}
