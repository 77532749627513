import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxPopperjsModule, NgxPopperjsPlacements } from 'ngx-popperjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CiNavbarComponent } from './components/ci-navbar/ci-navbar.component';
import { CiCallDataComponent } from './components/ci-call-data/ci-call-data.component';
import { CiDashboardComponent } from './components/ci-dashboard/ci-dashboard.component';
import { CiCostSettingsComponent } from './components/ci-cost-settings/ci-cost-settings.component';
import { CiAccountComponent } from './components/ci-account/ci-account.component';
import { CiCallDataArchiveComponent } from './components/ci-call-data-archive/ci-call-data-archive.component';
import { CiReportDailyComponent } from './components/ci-report-daily/ci-report-daily.component';
import { CiReportMonthlyComponent } from './components/ci-report-monthly/ci-report-monthly.component';
import { CiReportWeeklyComponent } from './components/ci-report-weekly/ci-report-weekly.component';
import { CiReportOverviewComponent } from './components/ci-report-overview/ci-report-overview.component';
import { CiStatisticsComponent } from './components/ci-statistics/ci-statistics.component';
import { CiStatsCountsComponent } from './components/ci-stats-counts/ci-stats-counts.component';
import { CiSidenavComponent } from './components/ci-sidenav/ci-sidenav.component';
import { CiSidenavReportComponent } from './components/ci-sidenav-report/ci-sidenav-report.component';
import { CiFooterComponent } from './components/ci-footer/ci-footer.component';
import { CiManageUsersComponent } from './components/ci-manage-users/ci-manage-users.component';
import { CiReportsSidenavComponent } from './components/ci-reports-sidenav/ci-reports-sidenav.component';
import { CiLoginComponent } from './components/ci-login/ci-login.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CiPaginationComponent } from './components/ci-pagination/ci-pagination.component';
import { CiLogoutComponent } from './components/ci-logout/ci-logout.component';
import { DashboardGraphComponent } from './components/dashboard-graph/dashboard-graph.component';
import { TimelineComponent } from './components/timeline/timeline.component';

@NgModule({
  declarations: [
    AppComponent,
    CiNavbarComponent,
    CiCallDataComponent,
    CiDashboardComponent,
    CiCostSettingsComponent,
    CiAccountComponent,
    CiCallDataArchiveComponent,
    CiReportDailyComponent,
    CiReportMonthlyComponent,
    CiReportWeeklyComponent,
    CiReportOverviewComponent,
    CiStatisticsComponent,
    CiStatsCountsComponent,
    CiSidenavComponent,
    CiSidenavReportComponent,
    CiFooterComponent,
    CiManageUsersComponent,
    CiReportsSidenavComponent,
    CiLoginComponent,
    CiPaginationComponent,
    CiLogoutComponent,
    DashboardGraphComponent,
    TimelineComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxPopperjsModule.forRoot({ placement: NgxPopperjsPlacements.TOP }),

    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
