<div>
    <div class="w-full px-4 py-2 bg-black" id="right-container">
        <div class="container mx-auto mt-12  w-2/4">
            <div class="flex items-center justify-between bg-blueishBlack p-4 rounded-md">
                <div class="flex mr-2">
                    <div class="mr-5"><img src="assets/img/male.jpg" alt="male" /></div>

                    <div class="text-gray-500 text-sm">
                        <div class="font-awesome">{{account.first_name}} {{account.last_name}}</div>
                        <div>{{account.username}}</div>
                        <div>{{account.address}}, {{account.city}}, {{account.state}}, {{account.zip}}</div>
                        <div>{{account.phone}}</div>
                        <div class="text-blue-600">{{account.email}}</div>
                    </div>
                </div>
                <div class="mr-4">
                    <button (click)="editProfile()"
                        class="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-1 px-3 border border-blue-400 rounded shadow text-sm">
                        Update Profile
                    </button>

                </div>
                <div>
                    <button (click)="changePassword()"
                        class="bg-orange-600 hover:bg-orange-500 text-white font-semibold py-1 px-3 border border-orange-400 rounded shadow text-sm">
                        Change Password
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- update profile modal -->
<div *ngIf="openProfileModal" id="add-user-modal" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full bg-gray-800 bg-opacity-50">
    <div class="relative w-full h-full max-w-md md:h-auto mx-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" (click)="reset()"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="add-user-modal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Profile</h3>
                <form [formGroup]="formUpdateProfile" (ngSubmit)="onUpdateProfileSubmit()" class="space-y-6" action="#">
                    <div>
                        <label for="fname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First
                            Name*</label>
                        <input type="text" formControlName="first_name" name="first_name" id="first_name"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fp['first_name'].errors }">
                        <div *ngIf="submitted && fp['first_name'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fp['first_name'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="lname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last
                            Name*</label>
                        <input type="text" formControlName="last_name" name="last_name" id="last_name"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fp['last_name'].errors }">
                        <div *ngIf="submitted && fp['last_name'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fp['last_name'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="email"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email*</label>
                        <input type="email" formControlName="email" name="email" id="email"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fp['email'].errors }">
                        <div *ngIf="submitted && fp['email'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fp['email'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="phone"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone*</label>
                        <input type="text" formControlName="phone" name="phone" id="phone"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fp['phone'].errors }">
                        <div *ngIf="submitted && fp['phone'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fp['phone'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="address"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Address*</label>
                        <input type="text" formControlName="address" name="address" id="address"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fp['address'].errors }">
                        <div *ngIf="submitted && fp['address'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fp['address'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="city"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">City*</label>
                        <input type="text" formControlName="city" name="city" id="city"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fp['city'].errors }">
                        <div *ngIf="submitted && fp['city'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fp['city'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="state"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">State*</label>
                        <input type="text" formControlName="state" name="state" id="state"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fp['state'].errors }">
                        <div *ngIf="submitted && fp['state'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fp['state'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>
                    <div>
                        <label for="zip"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ZIP*</label>
                        <input type="text" formControlName="zip" name="zip" id="zip"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fp['zip'].errors }">
                        <div *ngIf="submitted && fp['zip'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fp['zip'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>

                    <button type="submit"
                        class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Update</button>
                    <button type="Button" (click)="reset()"
                        class="w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800">
                        Cancel</button>

                </form>
            </div>
        </div>
    </div>
</div>


<!--Change Password modal -->
<div *ngIf="openChangePassword" id="add-user-modal" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full bg-gray-800 bg-opacity-50">
    <div class="relative w-full h-full max-w-md md:h-auto mx-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" (click)="reset()"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="add-user-modal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Change Password</h3>
                <form [formGroup]="formChangePassword" (ngSubmit)="onChangePasswordSubmit()" class="space-y-6"
                    action="#">


                    <div>
                        <label for="password"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password*</label>
                        <input type="password" formControlName="password" name="password" id="password"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fc['password'].errors }">
                        <div *ngIf="submitted && fc['password'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fc['password'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>

                    <div>
                        <label for="password"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm
                            Password*</label>
                        <input type="password" formControlName="confirmPassword" name="confirmPassword"
                            id="confirmPassword"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            required [ngClass]="{ 'border-red-500': submitted && fc['confirmPassword'].errors }">
                        <div *ngIf="submitted && fc['confirmPassword'].errors" class="text-red-500 text-xs italic mt-1">
                            <div *ngIf="fc['confirmPassword'].errors['required']">Please fill out this field.</div>
                        </div>
                    </div>

                    <div *ngIf="submitted && formChangePassword?.errors" class="text-red-500 text-xs italic mt-1">
                        <div *ngIf="formChangePassword?.errors">Password does not match..</div>
                    </div>

                    <button type="submit"
                        class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Change</button>
                    <button type="Button" (click)="reset()"
                        class="w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800">
                        Cancel</button>

                </form>
            </div>
        </div>
    </div>
</div>