import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';

export interface NumberedPagination {
  index: number;
  maxPages: number;
  pages: number[];
}
export enum RulerFactoryOption {
  Start = 'START',
  End = 'END',
  Default = 'DEFAULT',
}

@Component({
  selector: 'app-ci-pagination',
  templateUrl: './ci-pagination.component.html',
  styleUrls: ['./ci-pagination.component.css'],
})
export class CiPaginationComponent implements OnInit {
  MAX_RULER_COUNT: number = 10;
  maxPages: number = 0;

  @Input() index: number = 1;
  @Input() totalCount: Subject<any> = new Subject();

  @Input() rulerLength: number = this.MAX_RULER_COUNT;
  @Output() page: EventEmitter<any> = new EventEmitter<any>();

  pageSize: number = 5;
  totalRecords: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.totalCount.subscribe(v => { 
      this.totalRecords = v;
      this.updateRuler();
    }); 
  }

  updateRuler() {
    this.maxPages = Math.ceil(this.totalRecords / this.pageSize);
    this.rulerLength =
      this.MAX_RULER_COUNT > this.maxPages
        ? this.maxPages
        : this.MAX_RULER_COUNT;
  }

  get pagination(): NumberedPagination {
    const { index, maxPages, rulerLength } = this;
    const pages = ruler(index, maxPages, rulerLength);
    return { index, maxPages, pages } as NumberedPagination;
  }

  navigateToPage(event: any, pageNumber: number): void {
    event.preventDefault();
    if (allowNavigation(pageNumber, this.index, this.maxPages)) {
      this.index = pageNumber;
      this.page.emit({
        pageNumber: this.index,
        pageSize: this.pageSize,
        isFetchMore: (this.maxPages - this.index) < 2,
      });
    }
  }

  trackByFn(index: number): number {
    return index;
  }

  onPerPage(event: any) {
    this.pageSize = event.target.value;
    this.index = 1;
    this.updateRuler();
    setTimeout(() => {
      this.page.emit({
        pageNumber: this.index,
        pageSize: this.pageSize,
        isFetchMore: (this.maxPages - this.index) < 2,
      });
    });
  }
}

const ruler = (
  currentIndex: number,
  maxPages: number,
  rulerLength: number
): number[] => {
  const array = new Array(rulerLength).fill(null);
  const min = Math.floor(rulerLength / 2);

  return array.map((_, index) =>
    rulerFactory(currentIndex, index, min, maxPages, rulerLength)
  );
};

const rulerOption = (
  currentIndex: number,
  min: number,
  maxPages: number
): RulerFactoryOption => {
  return currentIndex <= min
    ? RulerFactoryOption.Start
    : currentIndex >= maxPages - min
    ? RulerFactoryOption.End
    : RulerFactoryOption.Default;
};

const rulerFactory = (
  currentIndex: number,
  index: number,
  min: number,
  maxPages: number,
  rulerLength: number
): number => {
  const factory = {
    [RulerFactoryOption.Start]: () => index + 1,
    [RulerFactoryOption.End]: () => maxPages - rulerLength + index + 1,
    [RulerFactoryOption.Default]: () => currentIndex + index - min,
  };

  return factory[rulerOption(currentIndex, min, maxPages)]();
};

const allowNavigation = (
  pageNumber: number,
  index: number,
  maxPages: number
): boolean => {
  return pageNumber !== index && pageNumber > 0 && pageNumber <= maxPages;
};
