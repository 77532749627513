import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/services/ci-user/user.service';
import { ToastrService } from 'ngx-toastr';
import { PAGINATE } from 'src/app/utils/common';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/ci-auth/auth-service';
import Validation from 'src/app/utils/validation';
import { AccountService } from 'src/app/services/ci-account/account.service';

const initialState = {
  currentPage: 0,
  totalPages: 0,
  totalRecords: 0,
  data: [],
};

@Component({
  selector: 'app-ci-manage-users',
  templateUrl: './ci-manage-users.component.html',
  styleUrls: ['./ci-manage-users.component.css'],
})
export class CiManageUsersComponent implements OnInit {
  totalRecordsCount: Subject<any> = new Subject();
  showAddUserModal: boolean = false;
  showDeleteUser: boolean = false;
  deleteUserData: any = {};
  usersList: Array<any> = [];

  totalRecords: number = 0;
  recordsPerPage: number = 5;
  activePage: number = 1;
  itemsToDisplay: Array<any> = [];
  isFetching: boolean = false;

  form: FormGroup = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    username: new FormControl(''),
    password: new FormControl(''),
    address: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zip: new FormControl(''),
    status: new FormControl('active'),
  });
  submitted = false;
  isSubmitting = false;
  isEdit = false;
  selectedUser: any = {};
  resetFetch: boolean = false;
  CURRENT_API_CALL_STATUS: any = initialState;
  isAdmin: boolean = false;
  infoModal: boolean = false;
  infoData: any = {};
  openChangePassword: boolean = false;

  formChangePassword: FormGroup = new FormGroup(
    {
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
    },
    [Validation.match('password', 'confirmPassword')]
  );

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toster: ToastrService,
    private authUser: AuthService,
    private accountService: AccountService
  ) {
    this.isAdmin = this.authUser.isAdmin;
    this.isFetching = true;
    this.userService.fetchManageUsersByPageNumber(1);
    this.userService.manageUsers.subscribe((res) => {
      if (res.status) {
        this.CURRENT_API_CALL_STATUS = {
          currentPage: res.currentPage,
          totalPages: res.totalPages,
          totalRecords: res.totalRecords,
          data: res.data,
        };
        if (this.resetFetch) {
          this.usersList = [];
          this.totalRecords = 0;
        }
        this.usersList = [...this.usersList, ...res.data];
        this.totalRecords = this.usersList.length;
        this.buildRecords(this.activePage);
        setTimeout(() => {
          this.totalRecordsCount.next(this.totalRecords);
        });
      }
      this.isFetching = false;
    });
  }

  ngOnInit(): void {
    // this.initializeFormValidator();
  }

  fetchManageUsersPageNumber(pageNumber: number) {
    if (Number(pageNumber) <= this.CURRENT_API_CALL_STATUS.totalPages) {
      this.isFetching = true;
      this.userService.fetchManageUsersByPageNumber(Number(pageNumber) + 1);
    }
  }

  initializeFormValidator() {
    this.form = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      username: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(40),
        ],
      ],
      password: this.isEdit
        ? ['']
        : ['', [Validators.minLength(3), Validators.maxLength(40)]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      status: ['active', Validators.required],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get fc(): { [key: string]: AbstractControl } {
    return this.formChangePassword.controls;
  }

  openAddUserModal() {
    this.showAddUserModal = true;
  }

  closeAddUserModal() {
    this.showAddUserModal = false;
    this.form.reset();
    this.isEdit = false;
    this.selectedUser = {};
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const payload: any = {};
    for (const key in this.form.value) {
      payload[key] = this.form.value[key];
    }
    if (this.isEdit) {
      payload['id'] = this.selectedUser.id;
    }
    this.isSubmitting = true;
    if (this.isEdit) {
      this.userService.editUser(payload).subscribe((res) => {
        if (res.status) {
          this.toster.success('User modified successfully');
          this.refreshAfterAction();
          this.closeAddUserModal();
          this.form.reset();
          this.submitted = false;
        } else {
          this.toster.error(res.message);
        }
        this.isSubmitting = false;
      });
    } else {
      this.userService.addUser(payload).subscribe((res) => {
        if (res.status) {
          this.toster.success('User created successfully');
          this.refreshAfterAction();
          this.closeAddUserModal();
          this.form.reset();
          this.submitted = false;
        } else {
          this.toster.error(res.message);
        }
        this.isSubmitting = false;
      });
    }
  }

  editUser(event: any, data: any) {
    event.preventDefault();
    this.isEdit = true;
    this.form.patchValue(data);
    this.openAddUserModal();
    this.selectedUser = data;
  }

  deleteUser(event: any, data: any) {
    event.preventDefault();
    this.showDeleteUser = true;
    this.deleteUserData = data;
  }

  confirmDeleteUser(flag: boolean = false) {
    if (!flag) {
      this.showDeleteUser = false;
      this.deleteUserData = {};
      return;
    }
    this.showDeleteUser = false;
    this.userService.deleteUser(this.deleteUserData.id).subscribe((res) => {
      if (res.status) {
        this.toster.success('User deleted successfully');
        this.refreshAfterAction();
        this.closeAddUserModal();
      } else {
        this.toster.error(res.message);
      }
      this.deleteUserData = {};
    });
  }

  refreshAfterAction() {
    this.activePage = 1;
    this.resetFetch = true;
    this.CURRENT_API_CALL_STATUS = initialState;
    this.userService.fetchManageUsersByPageNumber(this.activePage);
  }

  buildRecords(pageNumber: number) {
    this.itemsToDisplay = PAGINATE(
      this.usersList,
      this.recordsPerPage,
      pageNumber
    );
  }

  pageEvent(event: any) {
    if (event.isFetchMore) {
      this.fetchManageUsersPageNumber(this.CURRENT_API_CALL_STATUS.currentPage);
    }
    this.activePage = event.pageNumber;
    this.recordsPerPage = event?.pageSize;
    this.buildRecords(event?.pageNumber);
  }

  showInfo(event: any, data: any, flag: boolean) {
    event.preventDefault();
    this.infoModal = flag;
    if (!flag) {
      this.infoData = {};
      return;
    }
    this.infoData = data;
  }

  showChangePasswordDialog(event: any) {
    event.preventDefault();
    this.infoModal = false;
    this.openChangePassword = true;
  }

  reset() {
    this.openChangePassword = false;
    this.submitted = false;
    this.infoData = {};
  }
  onChangePasswordSubmit() {
    this.submitted = true;
    if (this.formChangePassword.invalid) {
      return;
    }

    const payload: any = {};
    for (const key in this.formChangePassword.value) {
      if (key !== 'confirmPassword') {
        payload[key] = this.formChangePassword.value[key];
      }
    }
    payload['userid'] = this.infoData.id;
    this.accountService.changePassword(payload).subscribe((res) => {
      if (res.status) {
        this.toster.success('Password Change successfully');
        this.reset();
      } else {
        this.toster.error(res.message);
      }
    });
  }
}
