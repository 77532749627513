import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import {
  BACK_END_ENDPOINT,
  DASHBOARD_DATA_TYPES,
  getToken,
} from 'src/app/utils/common';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  dataTypes: Record<string, string> = DASHBOARD_DATA_TYPES;

  constructor(private http: HttpClient) {}

  getDashboardDetails(timeline: string, dateRange: any = null) {
    const API_LIST = [];
    for (const k in this.dataTypes) {
      const formData = new FormData();
      formData.append('token', getToken());
      formData.append('type', this.dataTypes[k]);
      formData.append('timeline', timeline?.toLowerCase());
      if (dateRange) {
        formData.append('custom_range', dateRange?.toLowerCase());
      }
      API_LIST.push(
        this.http.post<any>(
          `${BACK_END_ENDPOINT}/api/main_dashboard.php`,
          formData
        )
      );
    }

    return forkJoin(API_LIST);
  }

  loadDashboardGraph() {
    return of({
      data: [
        {
          block: '1',
          sub_title: 'Finance',
          description: 'This is for test',
        },
        {
          block: '2',
          title: 'Sales',
          sub_title: '$13,430',
          description: 'This is for test',
        },
        {
          block: '3',
          title: 'Investments',
          sub_title: '$13,430',
          description: 'This is for test',
        },
        {
          block: '4',
          title: 'Startup',
          sub_title: '$13,430',
          description: 'This is for test',
        },
        {
          block: '5',
          title: 'Main projets',
          sub_title: '$13,430',
          description: 'This is for test',
        },
        {
          block: '6',
          title: 'Development',
          sub_title: '$13,430',
          description: 'This is for test',
        },
        {
          block: '7',
          title: 'Outsourcing',
          sub_title: '$13,430',
          description: 'This is for test',
        },
      ],
    });
  }
}
