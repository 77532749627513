 <!-- Footer -->
 <footer class="bg-blueishBlack fixed bottom-0 w-full">
    <div class="flex items-center justify-between text-gray-500 px-3 py-3 text-sm">
        <div>2023 © <a href="/dashboard">ClikMedia Dashboard</a></div>
        <div>
            <span>About</span>&nbsp;&nbsp;
            <span>Terms of Use</span>&nbsp;&nbsp;
            <span>Privacy Policy</span>
        </div>
    </div>
</footer>