<nav aria-label="Page navigation" class="mt-5 flex justify-center">
    <div class="-mt-2 mr-5">
        <select id="pageSize" (change)="onPerPage($event)"
            class="bg-gray-700 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option value="5">Per page 5</option>
            <option value="10">Per page 10</option>
            <option value="15">Per page 15</option>
            <option value="20">Per page 20</option>
            <option value="25">Per page 25</option>
        </select>
    </div>
    <ul class="inline-flex -space-x-px">
        <li>
            <a href="#" (click)="navigateToPage($event, 1)"
                class="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">First</a>
        </li>
        <li>
            <a href="#" (click)="navigateToPage($event, index - 1)"
                class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</a>
        </li>

        <li *ngFor="let page of pagination.pages; trackBy: trackByFn">
            <a href="#"
                [ngClass]="[page === pagination.index ? 'px-3 py-2 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white' : 'px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white']"
                (click)="navigateToPage($event, page)">{{page}}</a>
        </li>

        <li>
            <a (click)="navigateToPage($event, index + 1)" href="#"
                class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
        </li>
        <li>
            <a (click)="navigateToPage($event, maxPages)" href="#"
                class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Last</a>
        </li>
    </ul>
</nav>