import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { BACK_END_ENDPOINT, REPORT_TYPES, getToken } from 'src/app/utils/common';

import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private http: HttpClient) { }

  reportTypes: any = REPORT_TYPES;

  statisticReports() {
    const API_LIST = [];
    for (const k in this.reportTypes) {
      const formData = new FormData();
      formData.append('token', getToken());
      formData.append('type', this.reportTypes[k]);
      API_LIST.push(this.http
        .post<any>(`${BACK_END_ENDPOINT}/api/dashboard.php`, formData))
    }

    return forkJoin(API_LIST);
  }
}
