import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/services/ci-dashboard/dashboard.service';
const dlib: any = d3;

enum BLOCKS {
  BLOCK_1 = 'block-1',
  BLOCK_2 = 'block-2',
  BLOCK_3 = 'block-3',
  BLOCK_4 = 'block-4',
  BLOCK_5 = 'block-5',
  BLOCK_6 = 'block-6',
  BLOCK_7 = 'block-7',
}

@Component({
  selector: 'app-dashboard-graph',
  templateUrl: './dashboard-graph.component.html',
  styleUrls: ['./dashboard-graph.component.css'],
})
export class DashboardGraphComponent implements OnInit {
  @Input() dashboardGraph: Subject<any> = new Subject();
  OFFSET_LEFT: number = 30;
  OFFSET_BOTTOM: number = 30;
  DASHBOARD_BLOCK_POSITIONS: any = {
    [BLOCKS.BLOCK_1]: {
      title: {
        transform: `translate(${0 + this.OFFSET_LEFT},215)`,
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      sub_title: {
        transform: `translate(${0 + this.OFFSET_LEFT},245)`,
        'font-size': '1.25rem',
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      description: {
        transform: `translate(${0 + this.OFFSET_LEFT},265)`,
        'font-size': '0.8rem',
        fill: '#ffffff',
      },
    },
    [BLOCKS.BLOCK_2]: {
      title: {
        transform: `translate(${507 + this.OFFSET_LEFT},36)`,
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      sub_title: {
        transform: `translate(${507 + this.OFFSET_LEFT},70)`,
        'font-size': '1.25rem',
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      description: {
        transform: `translate(${507 + this.OFFSET_LEFT},90)`,
        'font-size': '0.8rem',
        fill: '#ffffff',
      },
    },
    [BLOCKS.BLOCK_3]: {
      title: {
        transform: `translate(${507 + this.OFFSET_LEFT},180)`,
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      sub_title: {
        transform: `translate(${507 + this.OFFSET_LEFT},210)`,
        'font-size': '1.25rem',
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      description: {
        transform: `translate(${507 + this.OFFSET_LEFT},230)`,
        'font-size': '0.8rem',
        fill: '#ffffff',
      },
    },
    [BLOCKS.BLOCK_4]: {
      title: {
        transform: `translate(${507 + this.OFFSET_LEFT},325)`,
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      sub_title: {
        transform: `translate(${507 + this.OFFSET_LEFT},355)`,
        'font-size': '1.25rem',
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      description: {
        transform: `translate(${507 + this.OFFSET_LEFT},375)`,
        'font-size': '0.8rem',
        fill: '#ffffff',
      },
    },
    [BLOCKS.BLOCK_5]: {
      title: {
        transform: `translate(${1021 + this.OFFSET_LEFT},36)`,
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      sub_title: {
        transform: `translate(${1021 + this.OFFSET_LEFT},70)`,
        'font-size': '1.25rem',
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      description: {
        transform: `translate(${1021 + this.OFFSET_LEFT},210)`,
        'font-size': '0.8rem',
        fill: '#ffffff',
      },
    },
    [BLOCKS.BLOCK_6]: {
      title: {
        transform: `translate(${1021 + this.OFFSET_LEFT},180)`,
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      sub_title: {
        transform: `translate(${1021 + this.OFFSET_LEFT},210)`,
        'font-size': '1.25rem',
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      description: {
        transform: `translate(${1021 + this.OFFSET_LEFT},230)`,
        'font-size': '0.8rem',
        fill: '#ffffff',
      },
    },
    [BLOCKS.BLOCK_7]: {
      title: {
        transform: `translate(${1021 + this.OFFSET_LEFT},325)`,
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      sub_title: {
        transform: `translate(${1021 + this.OFFSET_LEFT},355)`,
        'font-size': '1.25rem',
        'font-weight': 'bold',
        fill: '#ffffff',
      },
      description: {
        transform: `translate(${1021 + this.OFFSET_LEFT},375)`,
        'font-size': '0.8rem',
        fill: '#ffffff',
      },
    },
  };
  graphData: Array<any> = [];
  constructor(private dashboardService: DashboardService) {}
  ngOnInit(): void {
    // this.loadDashboardGraph();
    this.dashboardGraph.subscribe((v) => {
      this.graphData = v;
      this.renderGraphData();
    });
  }

  loadDashboardGraph() {
    this.dashboardService.loadDashboardGraph().subscribe((res: any) => {
      this.graphData = res.data;
      this.renderGraphData();
    });
  }

  renderGraphData() {
    const svg = d3.selectAll('#main-graph');
    for (const key in this.graphData) {
      const group = this.graphData[key];
      const g = svg.selectAll(`.block-${group.block}`);
      if (g.size() > 0) {
        this.renderBlockData(group, g);
      }
    }
  }

  renderBlockData(group: any, svg: any) {
    const blockPositions =
      this.DASHBOARD_BLOCK_POSITIONS[`block-${group.block}`];
    svg.selectAll('text').remove();
    for (const key in blockPositions) {
      const styles = blockPositions[key];
      const _text = svg.append('text');
      for (const s in styles) {
        _text.attr(s, styles[s]);
      }
      _text.text(group[key]);
    }
  }
}
