import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/ci-account/account.service';
import { USER_TOKEN } from 'src/app/utils/common';
import Validation from '../../utils/validation';

@Component({
  selector: 'app-ci-account',
  templateUrl: './ci-account.component.html',
  styleUrls: ['./ci-account.component.css'],
})
export class CiAccountComponent implements OnInit {
  openProfileModal: boolean = false;
  openChangePassword: boolean = false;
  submitted: boolean = false;

  formUpdateProfile: FormGroup = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    address: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zip: new FormControl(''),
  });

  formChangePassword: FormGroup = new FormGroup(
    {
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
    },
    [Validation.match('password', 'confirmPassword')]
  );

  account: any = {};
  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private toster: ToastrService
  ) {}

  get fp(): { [key: string]: AbstractControl } {
    return this.formUpdateProfile.controls;
  }

  get fc(): { [key: string]: AbstractControl } {
    return this.formChangePassword.controls;
  }

  ngOnInit(): void {
    // this.getAccountDetails();

    this.accountService.accountInfo.subscribe((account) => {
      this.account = account;
    });
    if (localStorage.getItem(USER_TOKEN)) {
      this.accountService.fetchAccountDetails();
    }
  }

  reset() {
    this.openProfileModal = false;
    this.openChangePassword = false;
    this.submitted = false;
  }

  editProfile() {
    this.openProfileModal = true;
    this.formUpdateProfile.patchValue(this.account);
  }

  changePassword() {
    this.openChangePassword = true;
  }

  getAccountDetails() {
    this.accountService.getAccountDetails().subscribe((res) => {
      if (res.status) {
        this.account = res.data;
      }
    });
  }

  onUpdateProfileSubmit() {
    this.submitted = true;
    if (this.formUpdateProfile.invalid) {
      return;
    }

    const payload: any = {};
    for (const key in this.formUpdateProfile.value) {
      payload[key] = this.formUpdateProfile.value[key];
    }
    this.accountService.updateProfile(payload).subscribe((res) => {
      if (res.status) {
        this.toster.success('Profile Updated successfully');
        this.getAccountDetails();
        this.reset();
      } else {
        this.toster.error(res.message);
      }
    });
  }

  onChangePasswordSubmit() {
    this.submitted = true;
    if (this.formChangePassword.invalid) {
      return;
    }

    const payload: any = {};
    for (const key in this.formChangePassword.value) {
      if (key !== 'confirmPassword') {
        payload[key] = this.formChangePassword.value[key];
      }
    }


    this.accountService.changePassword(payload).subscribe(res => {
      if (res.status) {
        this.toster.success('Password Change successfully');
        this.reset();
      } else {
        this.toster.error(res.message);
      }
    });
  }
}
