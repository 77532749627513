import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CiDashboardComponent } from './components/ci-dashboard/ci-dashboard.component';
import { CiStatisticsComponent } from './components/ci-statistics/ci-statistics.component';
import { CiManageUsersComponent } from './components/ci-manage-users/ci-manage-users.component';
import { CiCostSettingsComponent } from './components/ci-cost-settings/ci-cost-settings.component';
import { CiCallDataComponent } from './components/ci-call-data/ci-call-data.component';
import { CiCallDataArchiveComponent } from './components/ci-call-data-archive/ci-call-data-archive.component';
import { CiStatsCountsComponent } from './components/ci-stats-counts/ci-stats-counts.component';
import { CiReportOverviewComponent } from './components/ci-report-overview/ci-report-overview.component';
import { CiAccountComponent } from './components/ci-account/ci-account.component';
import { CiReportDailyComponent } from './components/ci-report-daily/ci-report-daily.component';
import { CiReportWeeklyComponent } from './components/ci-report-weekly/ci-report-weekly.component';
import { CiReportMonthlyComponent } from './components/ci-report-monthly/ci-report-monthly.component';
import { CiLoginComponent } from './components/ci-login/ci-login.component';

import { AuthGuardService } from './services/ci-guard/auth-guard-service';
import { CiLogoutComponent } from './components/ci-logout/ci-logout.component';

const routes: Routes = [
  { path: '', component: CiLoginComponent },
  { path: 'dashboard', component: CiDashboardComponent, canActivate: [AuthGuardService] },
  { path: 'logout', component: CiLogoutComponent },
  {
    path: 'statistics', children: [
      {
        path: '',
        component: CiStatisticsComponent,
        pathMatch: 'full',
        canActivate: [AuthGuardService]
      },
      {
        path: 'manage-users',
        component: CiManageUsersComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'cost-settings',
        component: CiCostSettingsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'call-data',
        component: CiCallDataComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'call-data-archive',
        component: CiCallDataArchiveComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'stats-counts',
        component: CiStatsCountsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            component: CiReportOverviewComponent,
            pathMatch: 'full',
            canActivate: [AuthGuardService]
          },
          {
            path: 'daily',
            component: CiReportDailyComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'weekly',
            component: CiReportWeeklyComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'monthly',
            component: CiReportMonthlyComponent,
            canActivate: [AuthGuardService]
          }
        ]
      },
      {
        path: 'account',
        component: CiAccountComponent,
        canActivate: [AuthGuardService]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
